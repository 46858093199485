import React, { FC } from 'react';

import { FormControl, Props as FormControlProps } from '../../FormControl';
import { FileUploader, FileUploaderProps } from '../../FileUploader';

export type Props = FileUploaderProps & FormControlProps;

export const FormFileUploader: FC<Props> = ({
  label,
  isInvalid,
  isRequired,
  errorText,
  ...fileUploaderProps
}) => (
  <FormControl label={label} isInvalid={isInvalid} errorText={errorText} isRequired={isRequired}>
    <FileUploader isInvalid={isInvalid} {...fileUploaderProps} />
  </FormControl>
);
