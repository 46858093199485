interface StoredData<T> {
  data: T;
  expirationTimestamp: number;
}

export const setDataWithExpiration = <T>(
  key: string,
  data: T,
  expirationTimeInMinutes: number
): void => {
  const expirationTimestamp = Date.now() + expirationTimeInMinutes * 60 * 1000;
  const storedData: StoredData<T> = { data, expirationTimestamp };
  localStorage.setItem(key, JSON.stringify(storedData));

  setTimeout(() => {
    localStorage.removeItem(key);
  }, expirationTimeInMinutes * 60 * 1000);
};

export const getDataWithExpiration = <T>(key: string): T | null => {
  const item = localStorage.getItem(key);
  if (!item) {
    return null; // No data found
  }

  const { data, expirationTimestamp }: StoredData<T> = JSON.parse(item);
  if (Date.now() > expirationTimestamp) {
    localStorage.removeItem(key); // Delete expired data
    return null; // Data expired
  }

  return data;
};
