import type { UseMutationResult, UseMutationOptions } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';

import { capitalize } from './lib';
import type { MutationFn, MutationFnData } from './types';

export type Options<
  TMutateFn extends MutationFn = MutationFn,
  TData extends MutationFnData<TMutateFn> = MutationFnData<TMutateFn>,
  TError = unknown,
  TContext = unknown
> = Omit<UseMutationOptions<TData, TError, TData, TContext>, 'mutationKey' | 'mutationFn'> & {
  mutationFn: TMutateFn;
  secondaryKey?: string;
};

export type Result<
  TPrimaryKey extends string,
  TMutateFn extends MutationFn = MutationFn,
  TVariables extends Parameters<TMutateFn>[0] = Parameters<TMutateFn>[0],
  TData extends MutationFnData<TMutateFn> = MutationFnData<TMutateFn>,
  TError = unknown
> = Omit<UseMutationResult<TData, TError>, 'mutate' | 'mutateAsync'> & {
  [P in `mutate${Capitalize<TPrimaryKey>}`]: UseMutationResult<TData, TError, TVariables>['mutate'];
} & {
  [P in `mutate${Capitalize<TPrimaryKey>}Async`]: UseMutationResult<
    TData,
    TError,
    TVariables
  >['mutateAsync'];
};

export type HookOptions<
  TMutateFn extends MutationFn = MutationFn,
  TData extends MutationFnData<TMutateFn> = MutationFnData<TMutateFn>,
  TError = unknown,
  TVariables extends Parameters<TMutateFn>[0] = Parameters<TMutateFn>[0],
  TContext = unknown
> = Omit<Options<TMutateFn, TData, TError, TContext>, 'mutationKey' | 'mutationFn' | 'onError'> & {
  onError: (error: TError, variables: TVariables, context: TContext | undefined) => void;
};

export const createMutationQuery =
  <
    TMutateFn extends MutationFn = MutationFn,
    TVariables extends Parameters<TMutateFn>[0] = Parameters<TMutateFn>[0],
    TData extends MutationFnData<TMutateFn> = MutationFnData<TMutateFn>,
    TError = unknown,
    TContext = unknown,
    TPrimaryKey extends string = any
  >(
    primaryKey: TPrimaryKey,
    { mutationFn, secondaryKey, ...options }: Options<TMutateFn, TData, TError, TContext>
  ) =>
  (hookOptions?: HookOptions<TMutateFn, TData, TError, TVariables, TContext>) => {
    const { mutate, mutateAsync, ...mutation } = useMutation<TData, TError, TVariables, TContext>({
      ...options,
      ...hookOptions,
      mutationKey: secondaryKey ? [primaryKey, secondaryKey] : [primaryKey],
      mutationFn,
    });

    const capitalizedPrimaryKey = capitalize(primaryKey);

    return {
      ...mutation,
      [`mutate${capitalizedPrimaryKey}`]: mutate,
      [`mutate${capitalizedPrimaryKey}Async`]: mutateAsync,
    } as Result<TPrimaryKey, TMutateFn, TVariables, TData, TError>;
  };
