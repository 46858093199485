import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Calendar: FC<IconProps> = (props) => (
  <Icon width="24px" height="24px" viewBox="0 0 24 24" {...props}>
    <path d="M7 10.5V13.5H10V10.5H7Z" fill="#678C95" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 1.5H8.5V3H15.5V1.5H18V3C19.6569 3 21 4.34315 21 6V18C21 19.6569 19.6569 21 18 21H6C4.34315 21 3 19.6569 3 18V6C3 4.34315 4.34315 3 6 3V1.5ZM5.5 18V9H18.5V18C18.5 18.2761 18.2761 18.5 18 18.5H6C5.72386 18.5 5.5 18.2761 5.5 18Z"
      fill="currentColor"
    />
  </Icon>
);
