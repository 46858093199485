import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const FileIcon: FC<IconProps> = (props) => (
  <Icon width="20px" height="20px" viewBox="0 0 20 20" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.60451 3.03177C3.33203 3.56655 3.33203 4.26662 3.33203 5.66675V14.3334C3.33203 15.7335 3.33203 16.4336 3.60451 16.9684C3.8442 17.4388 4.22665 17.8212 4.69705 18.0609C5.23183 18.3334 5.9319 18.3334 7.33203 18.3334H12.6654C14.0655 18.3334 14.7656 18.3334 15.3003 18.0609C15.7707 17.8212 16.1532 17.4388 16.3929 16.9684C16.6654 16.4336 16.6654 15.7335 16.6654 14.3334V6.3857C16.6654 6.18187 16.6654 6.07996 16.6423 5.98405C16.6219 5.89902 16.5883 5.81774 16.5426 5.74318C16.491 5.65908 16.419 5.58702 16.2748 5.44289L12.8892 2.05727C12.7451 1.91315 12.673 1.84108 12.5889 1.78955C12.5144 1.74386 12.4331 1.71019 12.3481 1.68977C12.2522 1.66675 12.1502 1.66675 11.9464 1.66675H7.33203C5.9319 1.66675 5.23183 1.66675 4.69705 1.93923C4.22665 2.17892 3.8442 2.56137 3.60451 3.03177ZM9.9987 6.66675H5.83203V8.33341H9.9987V6.66675ZM14.1654 10.0001V11.6667H5.83203V10.0001H14.1654ZM12.4987 13.3334H5.83203V15.0001H12.4987V13.3334Z"
      fill="currentColor"
    />
  </Icon>
);
