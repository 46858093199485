export const styleSizeVariables = {
  md: {
    height: '64px',
    padding: '0px 32px',
    justifyContent: 'center',
    gap: '10px',
    textVariant: 'bodyMmd',
  },
  sm: {
    height: '56px',
    padding: '10px 24px',
    gap: '8px',
    textVariant: 'bodySmd',
  },
  xs: {
    height: '40px',
    padding: '8px 16px',
    gap: '6px',
    textVariant: 'captionM',
  },
};

export const iconSizeVariables = {
  md: {
    height: '24px',
    width: '24px',
  },
  sm: {
    height: '20px',
    width: '20px',
  },
  xs: {
    height: '16px',
    width: '16px',
  },
};

export const colorVariantVariables = {
  primary: {
    default: 'brandMars',
    hover: 'brandMars',
    textColor: 'greySun',
    textColorHover: 'greySun',
  },
  secondary: {
    default: 'greySaturn',
    hover: 'greyUranus',
    textColor: 'greyPluton',
    textColorHover: 'greyPluton',
  },
  pseudo: {
    default: 'transparent',
    hover: 'transparent',
    textColor: 'brandMars',
    textColorHover: 'brandMarsDark',
  },
};
