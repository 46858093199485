import React, { ChangeEvent, FC } from 'react';

import type { InputProps } from '@/shared/ui';
import { FormInput } from '@/shared/ui';

import { useField } from '../lib';
import type { FormControlProps } from './types';
import { formatNumber } from '../../lib';

export type Props = Omit<InputProps, 'value'> & FormControlProps & { isNumber?: boolean };

export const Input: FC<Props> = ({
  label,
  isDisabled,
  isRequired,
  onBlur,
  isNumber = false,
  ...props
}) => {
  const { error, invalid, onChange, value, ...field } = useField<Record<string, any>>({
    name: props.name ?? '',
    onBlur,
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value.replace(/ /g, '');
    if (/^\d*([.,]?\d*)$/.test(rawValue)) {
      onChange({
        ...e,
        target: {
          ...e.target,
          value: rawValue,
        },
      });
    }
  };

  return (
    <FormInput
      label={label}
      isInvalid={invalid}
      errorText={error}
      isDisabled={isDisabled}
      isRequired={isRequired}
      {...props}
      onChange={isNumber ? handleChange : onChange}
      value={isNumber ? formatNumber(value ?? '') : value ?? ''}
      {...field}
    />
  );
};
