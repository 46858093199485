import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const LogoIconMob: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 18 32" width="18px" height="32px" {...props}>
    <path d="M17.4857 0L0 2.24685V7.34426L17.4857 5.81136V0Z" fill="#F22557" />
    <path
      d="M10.707 10.2578C8.5573 10.2969 6.51071 11.1829 5.01527 12.7221V10.6159H0V31.9994H4.98356V24.4012C6.4867 25.9359 8.5391 26.8145 10.6911 26.8444C15.4105 26.8444 18 24.2906 18 18.5458C18 12.8011 15.4105 10.2578 10.707 10.2578ZM8.92073 22.7004C5.94539 22.7004 5.01527 21.6473 5.01527 18.5195C5.01527 15.3918 5.94539 14.3386 8.92073 14.3386C11.8961 14.3386 12.805 15.3918 12.805 18.5195C12.805 21.6473 11.9119 22.7004 8.92073 22.7004Z"
      fill="#00274F"
    />
  </Icon>
);
