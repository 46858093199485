import { useCallback } from 'react';
import type { FieldValues } from 'react-hook-form';
import { useController, useForm as useFormBase } from 'react-hook-form';
import type {
  Path,
  UseFormProps as UseFormBaseProps,
  UseFormReturn,
} from 'react-hook-form/dist/types';

import type { Option } from '@/shared/ui';

import { useFormContext } from './context';
import type { schemaValidator } from './schemaValidator';
import { resolver } from './schemaValidator';

interface FieldProps<TFieldValues> {
  name: Path<TFieldValues>;
  onBlur?: (event: React.FocusEvent<any, Element>) => void;
}

export const useField = <TFieldValues extends FieldValues>({
  name,
  onBlur,
}: FieldProps<TFieldValues>) => {
  const { control } = useFormContext<TFieldValues>();
  const { field, fieldState } = useController({
    name,
    control,
  });
  const { error, invalid } = fieldState;

  const handleBlur = (event: React.FocusEvent<unknown, Element>) => {
    onBlur?.(event);
    field.onBlur();
  };

  return {
    ...field,
    onBlur: handleBlur,
    invalid,
    error: error?.message,
  };
};

type UseFormProps<
  TFieldValues extends FieldValues = FieldValues,
  TContext extends object = object,
  TSchema = any
> = Omit<UseFormBaseProps<TFieldValues, TContext>, 'resolver'> & {
  schema?: TSchema;
};

export interface FieldError {
  code: string;
  message: string;
  field: string;
}

type UseFormSetErrors = (errors: FieldError[]) => void;

export const useForm = <
  TFieldValues extends FieldValues = FieldValues,
  TContext extends object = object,
  TSchema extends schemaValidator.Schema<unknown> = schemaValidator.Schema<unknown>
>({
  schema,
  ...props
}: UseFormProps<TFieldValues, TContext, TSchema>): UseFormReturn<TFieldValues, TContext> & {
  setErrors: UseFormSetErrors;
} => {
  const form = useFormBase<TFieldValues, TContext>({
    mode: 'all',
    ...props,
    resolver: schema ? resolver(schema) : undefined,
  });

  const setErrors: UseFormSetErrors = useCallback(
    (errors) => {
      errors.forEach(({ message, field }) =>
        form.setError(field as Path<TFieldValues>, {
          type: 'manual',
          message,
        })
      );
    },
    [form]
  );

  return { ...form, setErrors };
};

export const mapListToOptions = <T extends Record<string, any> = Record<string, any>[]>(
  array: T[] | undefined,
  label: keyof T,
  value: keyof T
): Option[] =>
  array
    ? array.map((item) => ({
        label: item[label] ?? '',
        value: item[value] ?? '',
      }))
    : [];
