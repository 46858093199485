import React, { FC } from 'react';
import { Button, Stack, StackProps } from '@chakra-ui/react';

import { NavItems } from './NavItems';
import { Text } from '../Text';

interface Props extends StackProps {
  buttonBorder?: string;
  isVisible?: boolean;
  onOpenInsuranceCase: VoidFunction;
}

export const DesktopNav: FC<Props> = ({ isVisible, onOpenInsuranceCase, ...restProps }) => (
  <Stack direction="row" alignItems="center" ml="auto" spacing="0" {...restProps}>
    {isVisible && <NavItems />}
    <Button
      onClick={onOpenInsuranceCase}
      bgColor="brandMars"
      height="52px"
      width="236px"
      ml="8px"
      borderRadius="8px"
      p="15px 16px 16px"
      _hover={{
        backgroundColor: 'brandMars',
      }}
      color="greySun"
      fontWeight={500}
      transition="none"
      boxShadow="0px 8px 8px 0px #F2285729"
    >
      <Text variant="captionM">Заявить о страховом событии</Text>
    </Button>
  </Stack>
);
