import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const LogoIcon: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 351 36" width="351px" height="36px" {...props}>
    <path d="M144.747 0H145.775V36H144.747V0Z" fill="#00274F" />
    <path d="M75.63 0.0361328L58.8091 2.23445V7.22175L75.63 5.72197V0.0361328Z" fill="#F22557" />
    <path
      d="M24.238 18.1978C24.238 21.2231 25.1368 22.276 28.0284 22.276C30.9201 22.276 31.8087 21.2488 31.8087 18.1978C31.8087 15.1469 30.9355 14.1196 28.0284 14.1196C25.1214 14.1196 24.238 15.1572 24.238 18.1978ZM19.1583 18.1516C19.1583 12.6044 22.24 10.0723 28.0284 10.0723C33.8169 10.0723 36.9191 12.6044 36.9191 18.1516C36.9191 23.6987 33.8374 26.3234 28.0284 26.3234C22.2194 26.3234 19.1583 23.6987 19.1583 18.1516Z"
      fill="#00274F"
    />
    <path
      d="M72.4461 11.464L58.8096 2.21875V7.20092L63.0623 10.0824L63.8995 10.5703C62.8465 10.519 61.8027 10.7876 60.9052 11.3407C58.8045 12.6094 57.7156 14.8385 57.7156 18.136C57.7156 23.6832 60.7973 26.3078 66.5909 26.3078C72.3845 26.3078 75.5021 23.6986 75.5021 18.1514C75.5021 14.9464 74.4389 12.7481 72.4461 11.464ZM66.5909 22.2501C63.6992 22.2501 62.7953 21.2229 62.7953 18.172C62.7953 15.121 63.6992 14.0938 66.5909 14.0938C69.4825 14.0938 70.366 15.121 70.366 18.172C70.366 21.2229 69.498 22.2758 66.5909 22.2758V22.2501Z"
      fill="#00274F"
    />
    <path
      d="M10.4059 10.042C8.31664 10.0801 6.32761 10.9444 4.87422 12.4458V10.3913H0V31.2496H4.8434V23.838C6.30427 25.335 8.29895 26.192 10.3905 26.2212C14.977 26.2212 17.4938 23.7301 17.4938 18.1265C17.4938 12.5228 14.977 10.042 10.4059 10.042ZM8.66984 22.179C5.77818 22.179 4.87422 21.1517 4.87422 18.1008C4.87422 15.0498 5.77818 14.0226 8.66984 14.0226C11.5615 14.0226 12.4449 15.0498 12.4449 18.1008C12.4449 21.1517 11.5769 22.179 8.66984 22.179Z"
      fill="#00274F"
    />
    <path
      d="M108.798 10.4209V16.0759H102.373V10.4209H97.4988V25.7269H102.373V19.9949H108.798V25.7269H113.673V10.4209H108.798Z"
      fill="#00274F"
    />
    <path
      d="M77.1548 18.1265C77.1548 23.7301 79.6766 26.2212 84.2581 26.2212C86.3498 26.1931 88.3449 25.336 89.8052 23.838V25.7332H94.6486V10.4015H89.7743V12.456C89.0548 11.7089 88.1949 11.111 87.2439 10.6968C86.2929 10.2825 85.2696 10.06 84.2324 10.042C79.6766 10.042 77.1548 12.5331 77.1548 18.1265ZM82.2036 18.1265C82.2036 15.0858 83.0716 14.0483 85.9787 14.0483C88.8858 14.0483 89.7743 15.0755 89.7743 18.1265C89.7743 21.1774 88.8704 22.2046 85.9787 22.2046C83.087 22.2046 82.2036 21.126 82.2036 18.1008V18.1265Z"
      fill="#00274F"
    />
    <path
      d="M51.0591 20.0726C50.6842 21.6699 49.6518 22.276 47.4638 22.276C44.5722 22.276 43.6733 21.2488 43.6733 18.1978C43.6733 15.1469 44.5722 14.1196 47.4638 14.1196C49.6672 14.1196 50.6996 14.7154 51.0591 16.3231L56.1439 15.9379C55.3838 11.9316 52.4151 10.0723 47.4536 10.0723C41.6548 10.0723 38.5834 12.6044 38.5834 18.1516C38.5834 23.6987 41.6394 26.3234 47.4536 26.3234C52.4048 26.3234 55.3478 24.423 56.1285 20.4578L51.0591 20.0726Z"
      fill="#00274F"
    />
    <path
      d="M134.3 25.7269L126.585 17.6887L133.889 10.4209H127.859L121.316 17.0672V10.4209H116.529V25.7269H121.316V20.375V19.0498V18.68L128.034 25.7269H134.3Z"
      fill="#00274F"
    />
    <path
      d="M171.949 12.703H178.369V25.6977H180.886V12.703H187.306V10.3711H171.949V12.703Z"
      fill="#00274F"
    />
    <path
      d="M224.918 10.3711L232.134 18.0344L224.949 25.6977H228.112L233.731 19.7191L239.35 25.6977H242.54L235.36 18.0036L242.545 10.3711H239.35L233.762 16.3189L228.236 10.3711H224.918Z"
      fill="#00274F"
    />
    <path
      d="M276.099 14.4899C276.099 11.9474 274.122 10.3809 270.963 10.3809H262.853V25.6818H271.035C274.497 25.6818 276.649 23.9663 276.649 21.1978C276.663 20.4429 276.442 19.7023 276.016 19.0791C275.589 18.4559 274.979 17.981 274.271 17.7206C274.841 17.3993 275.312 16.9287 275.634 16.3595C275.956 15.7902 276.117 15.1438 276.099 14.4899ZM265.365 16.7036V12.6716H270.973C272.653 12.6716 273.613 13.3804 273.613 14.6131C273.613 16.3389 272.144 16.7036 270.912 16.7036H265.365ZM271.066 23.3807H265.365V19.0046H271.097C273.018 19.0046 274.076 19.7699 274.076 21.1619C274.076 22.6411 273.064 23.3961 271.066 23.3961V23.3807Z"
      fill="#00274F"
    />
    <path
      d="M313.054 25.6977V10.3711H310.543V16.7657H301.652V10.3711H299.135V25.6977H301.652V19.0925H310.543V25.6977H313.054Z"
      fill="#00274F"
    />
    <path
      d="M331.982 10.3711H329.624L320.277 22.0304V10.3711H317.76V25.6977H320.123L329.47 14.0127V25.6977H331.982V10.3711Z"
      fill="#00274F"
    />
    <path
      d="M220.203 12.2398C218.796 10.8622 216.913 10.0787 214.944 10.0518C210.208 10.0518 207.702 12.8151 207.702 18.0438C207.702 23.2725 210.208 26.0204 214.944 26.0204C215.924 26.0149 216.894 25.8151 217.796 25.4325C218.699 25.0499 219.517 24.492 220.203 23.7912V25.743H222.704V10.4472H220.203V12.2398ZM220.141 18.5163C220.141 19.5949 220.141 20.6222 220.141 21.2899C220.137 21.5165 220.078 21.7387 219.969 21.9374C219.86 22.1361 219.704 22.3052 219.515 22.4301C218.255 23.2463 216.784 23.6765 215.283 23.668C213.356 23.668 212.165 23.2519 211.42 22.3274C210.675 21.4029 210.352 20.0315 210.352 18.0438C210.352 15.9122 210.711 14.5152 211.482 13.6471C212.252 12.7791 213.449 12.4042 215.283 12.4042C216.073 12.405 216.859 12.5227 217.614 12.7534C218.253 12.9772 218.869 13.2628 219.453 13.606C219.668 13.7268 219.846 13.9041 219.967 14.1187C220.088 14.3333 220.149 14.577 220.141 14.8233C220.136 15.7325 220.141 17.1655 220.141 18.5163Z"
      fill="#00274F"
    />
    <path
      d="M291.786 12.2398C290.379 10.8622 288.496 10.0787 286.527 10.0518C281.791 10.0518 279.285 12.8151 279.285 18.0438C279.285 23.2725 281.791 26.0204 286.527 26.0204C287.507 26.0149 288.477 25.8151 289.38 25.4325C290.282 25.0499 291.1 24.492 291.786 23.7912V25.743H294.303V10.4472H291.786V12.2398ZM291.735 18.5163C291.735 19.5949 291.735 20.6222 291.735 21.2899C291.73 21.5163 291.67 21.7383 291.561 21.9368C291.452 22.1353 291.297 22.3045 291.108 22.4301C289.847 23.2446 288.377 23.6747 286.876 23.668C284.945 23.668 283.753 23.2519 283.009 22.3274C282.264 21.4029 281.94 20.0315 281.94 18.0438C281.94 15.9122 282.3 14.5152 283.075 13.6471C283.851 12.7791 285.037 12.4042 286.876 12.4042C287.666 12.4058 288.452 12.5235 289.208 12.7534C289.847 12.9782 290.462 13.2637 291.047 13.606C291.26 13.7281 291.437 13.9057 291.558 14.12C291.679 14.3342 291.74 14.5773 291.735 14.8233C291.735 15.7325 291.735 17.1655 291.735 18.5163Z"
      fill="#00274F"
    />
    <path
      d="M251.288 10.0518C246.223 10.0518 243.542 12.7997 243.542 17.9975C243.542 23.1954 246.223 26.0204 251.288 26.0204C256.352 26.0204 259.033 23.2468 259.033 17.9975C259.033 12.7483 256.352 10.0518 251.288 10.0518ZM256.398 18.0284C256.398 22.1887 255.063 23.6782 251.288 23.6782C247.513 23.6782 246.193 22.199 246.193 18.0284C246.193 13.8577 247.574 12.3785 251.288 12.3785C255.001 12.3785 256.398 13.9194 256.398 18.0284Z"
      fill="#00274F"
    />
    <path
      d="M197.922 9.97949C195.958 10.0069 194.081 10.7885 192.678 12.1624V10.3801H190.176V30.4783H192.678V23.6984C193.362 24.3969 194.177 24.953 195.077 25.3347C195.977 25.7164 196.944 25.9162 197.922 25.9224C202.647 25.9224 205.148 23.1694 205.148 17.9561C205.148 12.7428 202.647 9.97949 197.922 9.97949ZM192.734 14.7459C192.728 14.5004 192.788 14.2577 192.909 14.0435C193.029 13.8292 193.205 13.6514 193.417 13.5286C194.002 13.1888 194.618 12.905 195.256 12.6812C196.011 12.4533 196.794 12.3357 197.583 12.3319C199.416 12.3319 200.618 12.7274 201.373 13.5749C202.128 14.4224 202.503 15.8348 202.503 17.9561C202.503 19.9438 202.154 21.346 201.435 22.2346C200.716 23.1232 199.504 23.57 197.583 23.57C196.798 23.5676 196.018 23.4517 195.266 23.2259C194.593 23.0169 193.952 22.7162 193.361 22.3322C193.171 22.2087 193.015 22.0406 192.906 21.8427C192.797 21.6448 192.738 21.4231 192.734 21.1971C192.734 20.5345 192.734 19.5175 192.734 18.4492C192.739 17.0932 192.739 15.6551 192.734 14.7459Z"
      fill="#00274F"
    />
    <path
      d="M347.939 21.2334C347.302 22.9489 345.925 23.6628 343.316 23.6628C339.675 23.6628 338.324 22.2658 338.257 18.3519H351V16.996C351 16.7905 350.964 16.5902 350.944 16.3899C350.43 12.1833 347.862 10.0518 343.316 10.0518C338.278 10.0518 335.612 12.7997 335.612 17.9975C335.612 23.1954 338.273 26.0204 343.316 26.0204C347.081 26.0204 349.48 24.5154 350.507 21.5467L347.939 21.2334ZM343.316 12.3939C346.398 12.3939 347.939 13.5547 348.288 16.1434H338.38C338.802 13.4828 340.25 12.3939 343.316 12.3939Z"
      fill="#00274F"
    />
    <path
      d="M168.117 21.2636C167.465 23.01 166.063 23.7342 163.397 23.7342C159.586 23.7342 158.235 22.2395 158.235 18.0278C158.235 13.8161 159.638 12.3265 163.397 12.3265C166.017 12.3265 167.439 13.061 168.107 14.8176L170.721 14.5146C169.72 11.4996 167.265 9.96387 163.397 9.96387C158.261 9.96387 155.559 12.7477 155.559 18.0072C155.559 23.2668 158.271 26.1225 163.397 26.1225C167.249 26.1225 169.704 24.5816 170.711 21.5769L168.117 21.2636Z"
      fill="#00274F"
    />
  </Icon>
);
