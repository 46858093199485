import React from 'react';

import type { TextareaProps } from '@/shared/ui';
import { FormTextarea } from '@/shared/ui';

import { useField } from '../lib';
import type { FormControlProps } from './types';

export type Props = Omit<TextareaProps, 'value' | 'onChange'> & FormControlProps;

export const Textarea: React.FC<Props> = ({ label, isDisabled, isRequired, ...props }) => {
  const { error, invalid, ...field } = useField<Record<string, any>>({
    onBlur: props.onBlur,
    name: props.name ?? '',
  });

  return (
    <FormTextarea
      label={label}
      isInvalid={invalid}
      errorText={error}
      isDisabled={isDisabled}
      isRequired={isRequired}
      {...props}
      {...field}
    />
  );
};
