import React, { FC } from 'react';
import { useRadio, Box, UseRadioProps } from '@chakra-ui/react';

import { Tag, TagProps } from '../../Tag';
import { CheckedIcon, UncheckedIcon } from '../../icons';

interface Props extends UseRadioProps {
  tagProps: TagProps;
}

export const RadioTag: FC<Props> = ({ tagProps, ...restProps }) => {
  const { getInputProps, getRadioProps } = useRadio(restProps);

  const inputProps = getInputProps();
  const radioProps = getRadioProps();

  return (
    <Box as="label">
      <input {...inputProps} />
      <Tag
        tagVariant="solid"
        bgColor={inputProps.checked ? 'brandDeepBlue' : 'greySaturn'}
        icon={inputProps.checked ? <CheckedIcon /> : <UncheckedIcon />}
        cursor="pointer"
        {...tagProps}
        {...radioProps}
      />
    </Box>
  );
};
