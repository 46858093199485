import { styled, Link } from '@chakra-ui/react';

import { theme } from '../theme';

export const LinkStyled = styled(Link, {
  baseStyle: {
    ...theme.typography.body16M,
    textDecoration: 'none',
    color: 'greyPluton',
    ':hover': {
      textDecoration: 'none',
      color: 'brandMars',
    },
    ':active': {
      color: 'brandMars',
    },
  },
});
