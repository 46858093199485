export const typography = {
  h1: {
    fontSize: { base: '40px', lg: '72px' },
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: { base: '46px', lg: '82px' },
  },
  h2: {
    fontSize: { base: '24px', lg: '48px' },
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: { base: '28px', lg: '56px' },
  },
  h3: {
    fontSize: { base: '20px', xl: '32px' },
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: { base: '24px', xl: '36px' },
  },
  h4: {
    fontSize: { base: '18px', xl: '24px' },
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: { base: '22px', xl: '28px' },
  },
  h5: {
    fontSize: { base: '16px', lg: '20px' },
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: { base: '20px', lg: '24px' },
  },

  bodyM: {
    fontSize: { base: '16px', lg: '18px' },
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: { base: '20px', lg: '22px' },
  },
  bodyS: {
    fontSize: { base: '14px', lg: '16px' },
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: { base: '18px', lg: '20px' },
  },
  caption: {
    fontSize: { base: '12px', lg: '14px' },
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: { base: '16px', lg: '18px' },
  },

  bodyMmd: {
    fontSize: { base: '16px', lg: '18px' },
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: { base: '20px', lg: '22px' },
  },
  bodySmd: {
    fontSize: { base: '14px', lg: '16px' },
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: { base: '18px', lg: '20px' },
  },
  captionM: {
    fontSize: { base: '12px', lg: '14px' },
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: { base: '16px', xl: '18px' },
  },

  title1: {
    fontSize: { base: '28px', md: '40px' },
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: { base: '26px', sm: '30px', md: '44px' },
  },
  title2: {
    fontSize: '22px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '110%',
  },
  body16M: {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '140%',
  },
  body16: {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '140%',
  },
  subheadline14: {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '140%',
  },
  extraLargeTitle: {
    fontSize: { base: '50px', md: '100px', lg: '130px' },
    fontStyle: 'normal',
    lineHeight: '90%',
    textTransform: 'uppercase',
  },
  extraLargeText: {
    fontSize: { base: '48px', md: '130px' },
    fontStyle: 'normal',
    lineHeight: '90%',
    textTransform: 'uppercase',
  },
  headerTitle: {
    fontSize: { base: '16px', md: '26px' },
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '90%',
    textTransform: 'uppercase',
  },
  note: {
    fontSize: '12px',
    lineHeight: '130%',
  },
};
