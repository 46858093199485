import React, { useState } from 'react';

import { Select, type SelectProps } from '../../Select';
import { FormControl, type Props as FormControlProps } from '../../FormControl';

export type Props = Pick<
  FormControlProps,
  'label' | 'legend' | 'isInvalid' | 'isDisabled' | 'isRequired' | 'errorText'
> &
  SelectProps;

export const FormSelect: React.FC<Props> = ({
  label,
  legend,
  isInvalid,
  isDisabled,
  isRequired,
  errorText,
  onOpen,
  onClose,
  isMultiple = false,
  ...selectProps
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuOpen = () => {
    setIsMenuOpen(true);
    onOpen?.();
  };

  const handleMenuClose = () => {
    setIsMenuOpen(false);
    onClose?.();
  };

  const isExist = Array.isArray(selectProps.selected)
    ? selectProps.selected.length > 0
    : !!selectProps.selected?.value;

  return (
    <FormControl
      label={label}
      legend={legend}
      isInvalid={isInvalid}
      errorText={errorText}
      isDisabled={isDisabled}
      isRequired={isRequired}
      hasValue={Boolean(selectProps.selected) && isExist}
      isMenuOpen={isMenuOpen}
    >
      <Select
        isMultiple={isMultiple}
        isDisabled={isDisabled}
        isInvalid={isInvalid}
        {...selectProps}
        onOpen={handleMenuOpen}
        onClose={handleMenuClose}
        fieldProps={{ ...selectProps.fieldProps, hasLabel: Boolean(label) }}
      />
    </FormControl>
  );
};
