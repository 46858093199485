import React, { FC, useState } from 'react';
import { Stack, Popover, PopoverTrigger, PopoverContent, Link, IconButton } from '@chakra-ui/react';

import { theme } from '../theme';
import { Text } from '../Text';
import { ChevronDownIcon } from '../icons';
import {capitalize, trackHeaderClick} from '../../lib';
import { NavItem } from './types';

interface Props {
  navItem: NavItem;
  onPopoverOpen: (id: number | undefined) => void;
  onPopoverClose: () => void;
  activePopoverId: number | null;
}

export const NavItemPopover: FC<Props> = ({
  navItem,
  onPopoverOpen,
  onPopoverClose,
  activePopoverId,
}) => {
  const [isOpen, setIsOpen] = useState(activePopoverId === navItem.id);

  const handlePopoverClick = () => {
    trackHeaderClick(navItem.name);

    if (isOpen) {
      onPopoverClose();
    } else {
      onPopoverOpen(navItem.id);
    }
    setIsOpen(!isOpen);
  };

  return (
    <div>
      {navItem.children ? (
        <Popover isOpen={activePopoverId === navItem.id} onClose={onPopoverClose}>
          <PopoverTrigger>
            <Stack
              height={{ lg: '96px' }}
              direction="row"
              alignItems="center"
              onClick={handlePopoverClick}
              gap="2px"
            >
              <Text as="span" variant="bodyS" color="greyPluton">
                {navItem.label}
              </Text>
              <IconButton
                aria-label="Профиль пользователя"
                icon={<ChevronDownIcon width="20px" height="20px" color="greyNeptune" />}
                colorScheme="greySun"
                variant="solid"
                minW="unset"
                width="16px"
                p="0"
              />
            </Stack>
          </PopoverTrigger>
          <PopoverContent
            border={0}
            boxShadow="0px 1px 1px 0px rgba(65, 67, 78, 0.06), 0px 8px 24px -4px rgba(65, 67, 78, 0.20), 0px 0px 0px 0.5px rgba(65, 67, 78, 0.06)"
            pt="20px"
            pb="20px"
            pl="15px"
            mt="-30px"
            width="384px"
            rounded="24px"
            variants={{}}
          >
            <Stack spacing={0}>
              {navItem.children.map((item) => (
                <Link
                  href={item.href}
                  px="8px"
                  py="12px"
                  color="greyPluton"
                  textDecoration="none"
                  _hover={{
                    textDecoration: 'none',
                  }}
                  {...theme.typography.body16}
                >
                  <Stack direction="row" spacing={2}>
                    <Stack justifyContent="center">{item?.icon}</Stack>
                    <Stack gap={0}>
                      <Text as="span" variant="bodyS" color="greyPluton">
                        {item.label}
                      </Text>
                      <Text as="span" variant="bodyS" color="greyNeptune">
                        {item?.description}
                      </Text>
                    </Stack>
                  </Stack>
                </Link>
              ))}
            </Stack>
          </PopoverContent>
        </Popover>
      ) : (
        <Link
          href={navItem.href}
          px="8px"
          py="12px"
          color="greyPluton"
          _hover={{
            textDecoration: 'none',
          }}
          textDecoration="none"
          {...theme.typography.body16}
        >
          <Text as="span" variant="bodyS" color="greyPluton">
            {capitalize(navItem.label)}
          </Text>
        </Link>
      )}
    </div>
  );
};
