import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const RussianFlag: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path d="M0 4V19.9999H24V4H0Z" fill="white" />
    <path d="M0 4H24V20.0002H0V4Z" fill="#0052B4" />
    <path d="M0 4H24V9.3332H0V4Z" fill="white" />
    <path d="M0 14.666H24V19.9992H0V14.666Z" fill="#D80027" />
  </Icon>
);
