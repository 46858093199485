import { useEffect, useState, RefObject } from 'react';

import { useScrollDirection } from './useScrollDirection';

export const usePageHeader = (
  stackRef: RefObject<HTMLDivElement>,
  isShownInStart: boolean = false
) => {
  const [scrollY, setScrollY] = useState(0);
  const [showHeader, setShowHeader] = useState(isShownInStart);
  const { isScrollingUp } = useScrollDirection();

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    setShowHeader(
      (scrollY > (stackRef.current?.offsetHeight || 0) && isScrollingUp) ||
        (isShownInStart && scrollY === 0)
    );
  }, [scrollY, isShownInStart]);

  return { showHeader };
};
