import React, { FC } from 'react';
import { IconButton, type IconButtonProps } from '@chakra-ui/react';

import { CloseIcon } from '../../icons';

interface Props extends Omit<IconButtonProps, 'aria-label'> {
  onClose: VoidFunction;
}

export const CloseButton: FC<Props> = ({ onClose, ...restProps }) => (
  <IconButton
    aria-label="Закрыть меню"
    icon={<CloseIcon width={{ base: '24px', lg: '20px' }} height="auto" />}
    onClick={onClose}
    cursor="pointer"
    color={{ base: 'brandDeepBlue', lg: 'greySun' }}
    bgColor={{ base: 'greySun', lg: 'opacityOverlayDark' }}
    borderRadius="50%"
    _hover={{ bgColor: { base: 'greySun', lg: 'opacityOverlayDark' } }}
    _focusVisible={{
      boxShadow: 'none',
    }}
    {...restProps}
  />
);
