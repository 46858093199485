import React from 'react';
import ReactDOM from 'react-dom/client';
import * as amplitude from '@amplitude/analytics-browser';

import 'react-datepicker/dist/react-datepicker.css';
import './index.css';
import Config from './config.json';
import App from './app';
import { loadBviScripts } from './bviScripts';

amplitude.init(Config.AMPLITUDE_API_KEY);

loadBviScripts();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
