import { useEffect, useState } from 'react';

interface UseScrollDirectionResult {
  isScrollingDown: boolean;
  isScrollingUp: boolean;
}

enum ScrollDirection {
  DOWN = 'down',
  UP = 'up',
}

const { DOWN, UP } = ScrollDirection;

// TODO: вынести эту константу в хедер и использовать везде для высоты хедера
const THRESHOLD = 92;

export const useScrollDirection = (threshold = THRESHOLD): UseScrollDirectionResult => {
  const [scrollDir, setScrollDir] = useState(DOWN);

  useEffect(() => {
    let lastScrollY = window.scrollY;
    let ticking = false;

    const updateScrollDir = () => {
      const currentScrollY = window.scrollY;

      if (Math.abs(currentScrollY - lastScrollY) < threshold) {
        ticking = false;
        return;
      }
      setScrollDir(currentScrollY > lastScrollY ? DOWN : UP);
      lastScrollY = currentScrollY > 0 ? currentScrollY : 0;
      ticking = false;
    };

    const handleScroll = () => {
      if (!ticking) {
        requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [scrollDir]);

  return { isScrollingDown: scrollDir === DOWN, isScrollingUp: scrollDir === UP };
};
