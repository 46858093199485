import React from 'react';
import { Box, MenuItem, MenuList, Stack } from '@chakra-ui/react';

import { Text } from '../../Text';
import { CheckIcon } from '../../icons';
import { SuggestProps } from './types';

export const SingleSuggest: React.FC<SuggestProps> = ({
  onSelect,
  selected = { value: '', label: '' },
  isOptionsLoading,
  options,
  onChange,
  ...rest
}) => (
  <Stack
    sx={{
      div: {
        minWidth: 'auto !important',
      },
    }}
  >
    <MenuList
      maxH={{ base: '280px', md: '370px' }}
      zIndex="121"
      rootProps={{
        style: {
          width: '100%',
        },
      }}
      padding="12px 16px"
      boxShadow="0px 6px 24px 0px rgba(0, 0, 0, 0.05)"
      bg="greySun"
      border="1px solid"
      borderColor="greySaturn"
      width="inherit"
      borderRadius="16px"
      overflowY="auto"
      sx={{
        boxShadow: 'none',
        minWidth: '200px',
        '&::-webkit-scrollbar': {
          width: '4px',
          borderRadius: '10px',
          marginTop: '8px',
          marginBottom: '8px',
        },
        '&::-webkit-scrollbar-track': {
          width: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: 'greyUranus',
          borderRadius: '10px',
        },
      }}
      {...rest}
    >
      {isOptionsLoading ? (
        <Box pl={3}>Загрузка... </Box>
      ) : (
        options.map((option) => (
          <MenuItem
            cursor="pointer"
            key={option.value}
            data-focus="true"
            display="flex"
            justifyContent="space-between"
            flexDirection="row"
            padding="10px 16px"
            bg="greySun"
            onClick={() => {
              onSelect(option);
              onChange(option);
            }}
            _hover={{ cursor: 'pointer' }}
          >
            <Text variant="bodyM" color="greyPluton">
              {option.label}
            </Text>
            {selected && 'value' in selected && selected.value === option.value && (
              <CheckIcon w="24px" h="24px" color="greyPluton" />
            )}
          </MenuItem>
        ))
      )}
    </MenuList>
  </Stack>
);
