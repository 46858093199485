import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const EyeIcon: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path
      d="M12 7C7.34117 7 3.73517 10.3333 2.49988 12C3.91164 13.6667 7.794 17 12.0293 17C16.2646 17 19.9412 13.6667 21 12C20.1176 10.3333 16.6588 7 12 7Z"
      stroke="#9496A1"
      strokeWidth="1.2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C11.6925 9 11.3959 9.04625 11.1166 9.13218C11.6375 9.36736 12 9.89135 12 10.5C12 11.3284 11.3284 12 10.5 12C9.89135 12 9.36736 11.6375 9.13218 11.1166C9.04625 11.3959 9 11.6925 9 12C9 13.6569 10.3431 15 12 15Z"
      fill="#9496A1"
    />
  </Icon>
);
