import React, { FC, useState } from 'react';
import { Box, Stack } from '@chakra-ui/react';

import { NAV_ITEMS } from './mock';
import { NavItemPopover } from './NavItemPopover';

export const NavItems: FC = () => {
  const [activePopoverId, setActivePopoverId] = useState<number | null>(null); // Keeps track of currently open popover id
  return (
    <Stack direction="row" gap={0} alignItems="center">
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          <Stack
            justify="center"
            height={{ lg: '96px' }}
            pr={{ lg: '8px', xxl: '32px' }}
            _hover={{
              cursor: 'pointer',
            }}
          >
            <NavItemPopover
              key={navItem.id}
              navItem={navItem}
              onPopoverOpen={() => setActivePopoverId(navItem.id!)}
              onPopoverClose={() => setActivePopoverId(null)}
              activePopoverId={activePopoverId}
            />
          </Stack>
        </Box>
      ))}
    </Stack>
  );
};
