export const utcTimeFormat = (date: Date) => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
    timeZone: 'UTC',
    timeZoneName: 'short',
  };

  const utcString = new Intl.DateTimeFormat('en-US', options)
    .format(date)
    .replace(',', '')
    .replace(' UTC', 'Z');

  return utcString;
};
