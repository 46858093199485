import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const ChevronUpIcon: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 20 20" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.5 8.44444L2.36944 14.5L1.5 13.8189L9.5 5.5L17.5 13.8189L16.6306 14.5L9.5 8.44444Z"
      fill="#025366"
    />
  </Icon>
);
