import React, { FC, ReactElement } from 'react';
import { StackProps, Stack } from '@chakra-ui/react';

interface Props extends StackProps {
  children: ReactElement | ReactElement[];
}

export const ContentWrapperWithDivider: FC<Props> = ({ children, ...restProps }) => (
  <Stack
    borderRadius={{ base: '24px', sm: '32px', lg: 'none' }}
    bgColor={{ base: 'white', lg: 'none' }}
    width={{ lg: '1056px', xl: '1224px', xxl: '1608px' }}
    py={{ base: '32px', sm: '48px', lg: 0 }}
    px={{ base: '16px', sm: '32px', lg: 0 }}
    mx={{ lg: 'auto' }}
    mt={{ base: '12px', lg: 0 }}
    {...restProps}
  >
    {children}
  </Stack>
);
