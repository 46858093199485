import { SizeVariables } from './types';

export const VARIANTS: SizeVariables = {
  h1: {
    textVariant: 'h1',
    descriptionVariant: 'bodyM',
    padding: '20px',
  },
  h2: {
    textVariant: 'h2',
    descriptionVariant: 'bodyM',
    padding: '16px',
  },
  h3: {
    textVariant: 'h3',
    descriptionVariant: 'bodyS',
    padding: '12px',
  },
  h4: {
    textVariant: 'h4',
    descriptionVariant: 'bodyS',
    padding: '8px',
  },
  h5: {
    textVariant: 'h5',
    descriptionVariant: 'bodyS',
    padding: '4px',
  },
  size6: {
    textVariant: 'h4',
    descriptionVariant: 'bodyS',
    padding: '8px',
  },
};
