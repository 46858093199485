import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const CloseIcon: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path
      d="M5.1913 4.26158L3.95982 5.49306L9.80142 12.3328L3.76465 19.3399L4.96189 20.5372L11.969 14.5004L18.8087 20.342L20.0402 19.1105L14.1986 12.2708L20.2354 5.26365L19.0382 4.06641L12.031 10.1032L5.1913 4.26158Z"
      fill="currentColor"
    />
  </Icon>
);
