import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const CheckIcon: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path
      d="M9.75 18.5L3 12.5L4.125 11.3333L9.75 14.8333L19.875 5.5L21 6.66667L9.75 18.5Z"
      fill="currentColor"
    />
  </Icon>
);
