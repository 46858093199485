import React from 'react';

import { Input, type InputProps } from '../../Input';
import { FormControl, type Props as FormControlProps } from '../../FormControl';

export type Props = InputProps & FormControlProps;

export const FormInput: React.FC<Props> = ({
  label,
  legend,
  isInvalid,
  isDisabled,
  isRequired,
  errorText,
  hint,
  hasCounter,
  maxLength,
  ...inputProps
}) => (
  <FormControl
    label={label}
    legend={legend}
    isInvalid={isInvalid}
    errorText={errorText}
    isDisabled={isDisabled}
    isRequired={isRequired}
    hasValue={!!inputProps.value}
    value={inputProps.value}
    hasLeftIcon={!!inputProps.leftIcon}
    hint={hint}
    counter={maxLength}
    hasCounter={hasCounter}
  >
    <Input isInvalid={isInvalid} hasLabel={Boolean(label)} {...inputProps} />
  </FormControl>
);
