import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const ChevronLeftIcon: FC<IconProps> = (props) => (
  <Icon width="16px" height="16px" viewBox="0 0 16 16" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.21737 7.99998L11.6001 2.29553L10.9947 1.59998L3.6001 7.99998L10.9947 14.4L11.6001 13.7044L6.21737 7.99998Z"
      fill="currentColor"
    />
  </Icon>
);
