import { useState, FocusEvent } from 'react';
import useDebouncedCallback from 'beautiful-react-hooks/useDebouncedCallback';

import { getTrackedFieldState, FieldState } from '@/shared/lib';
import { Fields, FormData } from '@/entities/mortgageWithCashback';

interface UseTrackOnFormBlurProps {
  form: FormData;
  trackCallback: (name: string, value: FieldState) => void;
  dataMap: Record<any, any>;
}

interface UseTrackOnFormBlurResult {
  handleFormBlur: (event: FocusEvent<HTMLFormElement>) => void;
}

export const useTrackOnFormBlur = ({
  form,
  trackCallback,
  dataMap,
}: UseTrackOnFormBlurProps): UseTrackOnFormBlurResult => {
  const [trackedFields, setTrackedFields] = useState<string[]>([]);

  const handleFormBlur = useDebouncedCallback(
    (event: FocusEvent<HTMLFormElement>) => {
      // ternary for select fields
      const { name = '', value = '' } =
        event.target.name && event.target.value
          ? event.target
          : event.target.parentElement?.querySelector('input') || {};

      if (!name || trackedFields.includes(name)) {
        return;
      }

      setTrackedFields((prevState) => [...prevState, name]);
      const fieldState = form.control.getFieldState(name as Fields);
      trackCallback(
        dataMap[name as keyof typeof dataMap],
        getTrackedFieldState(value, fieldState.invalid)
      );
    },
    [trackedFields]
  );

  return {
    handleFormBlur,
  };
};
