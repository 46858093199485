import React from 'react';

import { CloseIcon } from '../../icons';
import { Input, type InputProps } from '../../Input';
import { FormControl, type Props as FormControlProps } from '../../FormControl';

export type Props = InputProps & FormControlProps & { onClear: () => void };

export const FormInputClearable: React.FC<Props> = ({
  label,
  legend,
  isInvalid,
  isDisabled,
  isRequired,
  errorText,
  hint,
  hasCounter,
  maxLength,
  onClear,
  ...inputProps
}) => (
  <FormControl
    label={label}
    legend={legend}
    isInvalid={isInvalid}
    errorText={errorText}
    isDisabled={isDisabled}
    isRequired={isRequired}
    hasValue={!!inputProps.value}
    value={inputProps.value}
    hasLeftIcon={!!inputProps.leftIcon}
    hint={hint}
    counter={maxLength}
    hasCounter={hasCounter}
  >
    <Input
      isInvalid={isInvalid}
      hasLabel={Boolean(label)}
      rightIcon={
        inputProps.value ? (
          <CloseIcon
            color="greyPluton"
            width="24px"
            height="100%"
            cursor="pointer"
            onClick={onClear}
          />
        ) : (
          inputProps?.rightIcon
        )
      }
      {...inputProps}
    />
  </FormControl>
);
