import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const MessageIcon: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 20 20" {...props}>
    <path
      d="M5.8335 10L13.3335 10"
      stroke="#5B5D6B"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.8335 6.66663L13.3335 6.66663"
      stroke="#5B5D6B"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.8335 13.3334L13.3335 13.3334"
      stroke="#5B5D6B"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.5 17.5C3.39543 17.5 2.5 16.6046 2.5 15.5L2.5 4.5C2.5 3.39543 3.39543 2.5 4.5 2.5L14.6667 2.5C15.7712 2.5 16.6667 3.39543 16.6667 4.5L16.6667 5.83333L19.1667 9.16667L16.6667 9.16667L16.6667 15.5C16.6667 16.6046 15.7712 17.5 14.6667 17.5L4.5 17.5Z"
      stroke="#5B5D6B"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
