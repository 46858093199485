import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const CheckedIcon: FC<IconProps> = (props) => (
  <Icon width="24px" height="24px" viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 22.0017C17.5228 22.0017 22 17.5246 22 12.0017C22 6.47886 17.5228 2.00171 12 2.00171C6.47715 2.00171 2 6.47886 2 12.0017C2 17.5246 6.47715 22.0017 12 22.0017ZM10.5894 16.5897L18.5894 8.58966L17.4109 7.41115L10.0002 14.8219L6.58942 11.4112L5.41091 12.5897L9.41091 16.5897C9.56719 16.7459 9.77915 16.8337 10.0002 16.8337C10.2212 16.8337 10.4331 16.7459 10.5894 16.5897Z"
      fill="currentColor"
    />
  </Icon>
);
