export const formatNumber = (num: string) => {
  const originalSeparator = num.includes('.') ? '.' : ',';

  const parts = num.split(originalSeparator);

  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  if (parts[1]) {
    parts[1] = parts[1].slice(0, 2);
  }

  return parts.join(originalSeparator);
};
