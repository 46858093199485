export const formatPhoneNumber = (phoneNumber: string) => {
  const numericPhoneNumber = phoneNumber?.replace(/\D/g, '');

  const formattedPhoneNumber = `+${numericPhoneNumber.slice(0, 1)} (${numericPhoneNumber.slice(
    1,
    4
  )}) ${numericPhoneNumber.slice(4, 7)}-${numericPhoneNumber.slice(
    7,
    9
  )}-${numericPhoneNumber.slice(9, 11)}`;

  return formattedPhoneNumber;
};
