import React, { ChangeEvent } from 'react';

import { FormInputClearable, type InputClearableProps } from '@/shared/ui';

import { useField } from '../lib';
import type { FormControlProps } from './types';
import { formatPassportNumber } from '../../lib';

export type Props = Omit<InputClearableProps, 'value'> &
  FormControlProps & { isPassport?: boolean };

export const InputClearable: React.FC<Props> = ({
  label,
  isDisabled,
  isRequired,
  onBlur,
  isPassport = false,
  ...props
}) => {
  const { error, invalid, onChange, value, ...field } = useField<Record<string, any>>({
    name: props.name ?? '',
    onBlur,
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value.replace(/\s+/g, '');
    if (/^\d{0,10}$/.test(rawValue)) {
      onChange({
        ...e,
        target: {
          ...e.target,
          value: rawValue,
        },
      });
    }
  };

  return (
    <FormInputClearable
      label={label}
      isInvalid={invalid}
      errorText={error}
      isDisabled={isDisabled}
      isRequired={isRequired}
      {...props}
      onChange={isPassport ? handleChange : onChange}
      value={isPassport ? formatPassportNumber(value ?? '') : value ?? ''}
      {...field}
    />
  );
};
