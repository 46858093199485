import React, { FC, ReactNode } from 'react';
import { TextProps } from '@chakra-ui/react';

import { Text } from '../../Text';
import { theme } from '../../theme';

interface Props extends TextProps {
  children: ReactNode;
}

export const TitleSecondary: FC<Props> = ({ children, ...restProps }) => (
  <Text
    as="h3"
    {...theme.typography.title2}
    sx={{
      // TODO: исправить в макетах шрифты - сделать их более предсказуемыми - если на десктопе используется title2, то на мобилках тоже должен использоваться title2
      '@media screen and (max-width: 1023px)': {
        ...theme.typography.body16M,
      },
    }}
    {...restProps}
  >
    {children}
  </Text>
);
