import React, { FC, ReactNode } from 'react';
import { Text, TextProps } from '@chakra-ui/react';

import { theme } from '../theme';

interface Props extends TextProps {
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
  children: ReactNode | ReactNode[];
}

export const Title: FC<Props> = ({ children, variant = 'h2', ...rest }) => (
  <Text as={variant} fontFamily="body" color="greyPluton" {...theme.typography[variant]} {...rest}>
    {children}
  </Text>
);
