import React, { FC } from 'react';
import { Stack, useMediaQuery } from '@chakra-ui/react';

import { Text } from '../Text';
import { VARIANTS } from './constants';
import { TextDescriptionSizeProps } from './types';

export const TextDescription: FC<TextDescriptionSizeProps> = ({
  size,
  title,
  description,
  titleColor = 'greyPluton',
  descriptionColor = 'greyPluton',
  ...rest
}) => {
  const [isThree1] = useMediaQuery('(min-width: 359px) and (max-width: 767px)');
  const [isThree2] = useMediaQuery('(min-width: 1279px) and (max-width: 1919px)');
  const isThreeLine = isThree1 || isThree2;
  return (
    <Stack
      spacing={VARIANTS[size]?.padding}
      direction={size === 'h5' ? 'column-reverse' : 'column'}
      position="relative"
      zIndex={1}
      {...rest}
    >
      {size === 'h5' ? (
        <Text
          isTruncated
          noOfLines={[1, 2, 3]}
          maxW={{ base: 300, sm: 330, lg: 340, xl: 392 }}
          variant={VARIANTS[size]?.textVariant!}
          color={titleColor}
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace={{ base: 'normal', sm: 'normal', lg: 'normal', md: 'normal' }}
          wordBreak="break-word"
        >
          {title}
        </Text>
      ) : (
        <Text
          variant={VARIANTS[size]?.textVariant!}
          color={titleColor}
          sx={{
            '::before': {
              background: 'linear-gradient(90deg, #fdbecd 0, rgba(253, 190, 205, 0) 45%);',
              content: '" " " "',
              position: 'absolute',
              zIndex: -1,
              height: isThreeLine ? '25%' : '40%',
              width: '100%',
              color: 'transparent',
              transform: 'skewY(-4deg)',
              transformOrigin: 'left bottom',
            },
          }}
        >
          {title}
        </Text>
      )}
      <Text variant={VARIANTS[size]?.descriptionVariant!} color={descriptionColor}>
        {description}
      </Text>
    </Stack>
  );
};
