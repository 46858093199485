import React from 'react';

import { FormSelect, type SelectProps } from '@/shared/ui';

import { useField } from '../lib';
import type { FormControlProps } from './types';

export type Props = Omit<SelectProps, 'value' | 'onChange'> & FormControlProps;

export const Select: React.FC<Props> = ({
  label,
  isDisabled,
  isRequired,
  isMultiple,
  ...props
}) => {
  const { error, invalid, ...field } = useField<Record<string, any>>({
    onBlur: props.onBlur,
    name: props.name ?? '',
  });

  return (
    <FormSelect
      isMultiple={isMultiple}
      label={label}
      isInvalid={invalid}
      errorText={error}
      isDisabled={isDisabled}
      isRequired={isRequired}
      {...props}
      {...field}
      onChange={(option) => field.onChange(option)}
    />
  );
};
