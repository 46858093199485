import React, { FC, ReactElement } from 'react';
import { Container } from '@chakra-ui/react';
import { ContainerProps } from '@chakra-ui/layout/dist/container';

interface Props extends ContainerProps {
  children: ReactElement | ReactElement[];
}

export const ContentContainer: FC<Props> = ({ children, ...restProps }) => (
  <Container maxWidth={{ base: 328, sm: 568, md: 800, lg: 1056, xl: 1216 }} px="0" {...restProps}>
    {children}
  </Container>
);
