import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const HamburgerIcon: FC<IconProps> = (props) => (
  <Icon width="28px" height="28px" viewBox="0 0 28 28" {...props}>
    <rect y="1" width="28" height="6" rx="2" fill="#00274F" />
    <rect y="11" width="28" height="6" rx="2" fill="#00274F" />
    <rect y="21" width="28" height="6" rx="2" fill="#00274F" />
  </Icon>
);
