import type { UseInfiniteQueryOptions, UseInfiniteQueryResult } from '@tanstack/react-query';
import { useInfiniteQuery } from '@tanstack/react-query';

import { queryClient } from './provider';
import type { QueryFn, QueryFnData, ResponseWithMeta, ReturnedQueryHook } from './types';

export type Options<
  TQueryFn extends QueryFn = QueryFn,
  TQueryFnData extends QueryFnData<TQueryFn> = QueryFnData<TQueryFn>,
  TError = unknown,
  TData extends ResponseWithMeta<any> = TQueryFnData
> = Omit<
  UseInfiniteQueryOptions<TQueryFnData, TError, TData>,
  'queryKey' | 'queryFn' | 'getNextPageParam' | 'initialPageParam' | 'select'
> & {
  queryFn: TQueryFn;
  secondaryKey?: string;
  getNextPageParam?: UseInfiniteQueryOptions<TQueryFnData, TError, TData>['getNextPageParam'];
  initialPageParam?: UseInfiniteQueryOptions<TQueryFnData, TError, TData>['initialPageParam'];
  select?: (data: TQueryFnData) => TData;
};

export type Result<
  TPrimaryKey extends string,
  TQueryFn extends QueryFn = QueryFn,
  TQueryFnData extends QueryFnData<TQueryFn> = QueryFnData<TQueryFn>,
  TData extends ResponseWithMeta<any> = TQueryFnData,
  TError = unknown
> = Omit<UseInfiniteQueryResult<TData, TError>, 'data'> & {
  [P in TPrimaryKey]: TData['data'];
};

const getQueryKey =
  <TParams>(initialKey: string[]) =>
  (params?: TParams) =>
    params ? [...initialKey, params] : initialKey;

export type HookOptions<
  TQueryFn extends QueryFn = QueryFn,
  TQueryFnData extends QueryFnData<TQueryFn> = QueryFnData<TQueryFn>,
  TError = unknown,
  TData extends ResponseWithMeta<any> = TQueryFnData
> = Pick<Options<TQueryFn, TQueryFnData, TError, TData>, 'enabled'>;

export const createInfiniteQuery = <
  TQueryFn extends QueryFn = QueryFn,
  TParams extends Parameters<TQueryFn>[0] = Parameters<TQueryFn>[0],
  TQueryFnData extends QueryFnData<TQueryFn> = QueryFnData<TQueryFn>,
  TError = unknown,
  TData extends ResponseWithMeta<any> = TQueryFnData,
  TPrimaryKey extends string = any
>(
  primaryKey: TPrimaryKey,
  {
    queryFn,
    secondaryKey,
    ...options
  }: Options<TQueryFn, TQueryFnData['data'], TError, TData['data']>
): ReturnedQueryHook<
  TParams,
  HookOptions,
  TData,
  Result<TPrimaryKey, TQueryFn, TQueryFnData, TData, TError>
> => {
  const queryKey = secondaryKey ? [primaryKey, secondaryKey] : [primaryKey];
  const addParamsToKeyIfExist = getQueryKey<TParams>(queryKey);

  const useHook = (
    params?: TParams,
    hookOptions?: HookOptions<TQueryFn, TQueryFnData, TError, TData>
  ) => {
    const { data, ...query } = useInfiniteQuery<TQueryFnData['data'], TError, TData['data']>({
      initialPageParam: 1,
      ...options,
      ...hookOptions,
      queryKey: addParamsToKeyIfExist(params),
      queryFn: async ({ pageParam }) =>
        (await queryFn({ ...params, page: pageParam || 1 } as TParams)).data,
      getNextPageParam: ({ meta }) => (meta?.page || 0) + 1,
      select: (selectData) =>
        options?.select ? options?.select(selectData.pages.flat()) : selectData.pages.flat(),
    });

    return {
      ...query,
      [primaryKey]: data,
    } as Result<TPrimaryKey, TQueryFn, TQueryFnData, TData, TError>;
  };

  useHook.invalidateQuery = (params?: TParams) => {
    queryClient.invalidateQueries({ queryKey: addParamsToKeyIfExist(params) });
  };

  useHook.setQueryData = (params: TParams, dataOrFn: TData | ((data?: TData) => TData)) => {
    queryClient.setQueryData(addParamsToKeyIfExist(params), dataOrFn);
  };

  useHook.fetchQuery = (params?: TParams) =>
    queryClient.fetchQuery<TQueryFnData, TError, TData['data']>({
      ...options,
      queryKey: addParamsToKeyIfExist(params),
      queryFn: async () => (await queryFn(params)).data,
    });

  useHook.getQueryData = (params?: TParams) =>
    queryClient.getQueryData<TData>(addParamsToKeyIfExist(params));

  return useHook;
};
