import React, { FC, RefObject } from 'react';

import { usePageHeader } from '@/shared/hooks';

import { Header } from './Header';

interface Props {
  stackRef: RefObject<HTMLDivElement>;
  onOpenInsuranceCase: VoidFunction;
  isShownInStart?: boolean;
}

export const HeaderSticky: FC<Props> = ({ stackRef, onOpenInsuranceCase, isShownInStart }) => {
  const { showHeader } = usePageHeader(stackRef, isShownInStart);

  return <Header isSticky isVisible={showHeader} onOpenInsuranceCase={onOpenInsuranceCase} />;
};
