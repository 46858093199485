import React, { FC } from 'react';

import { DatepickerProps, FormDatepicker } from '@/shared/ui';

import { useField } from '../lib';
import type { FormControlProps } from './types';

export type Props = Omit<DatepickerProps, 'onSelect'> & FormControlProps;

export const Datepicker: FC<Props> = ({ label, isDisabled, isRequired, onBlur, ...props }) => {
  const { error, invalid, onChange, value, ...field } = useField<Record<string, any>>({
    name: props.name ?? '',
    onBlur,
  });

  return (
    <FormDatepicker
      label={label}
      isInvalid={invalid}
      errorText={error}
      isDisabled={isDisabled}
      isRequired={isRequired}
      {...props}
      onChange={onChange}
      value={value ?? ''}
      {...field}
    />
  );
};
