import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const PlusIcon: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 40 40" {...props}>
    <path d="M17.1728 20.4813L18.5487 3H21.4513L22.8272 20.4813H17.1728Z" fill="currentColor" />
    <path
      d="M22.8272 20.5179L21.4513 37.9992H18.5487L17.1728 20.5179H22.8272Z"
      fill="currentColor"
    />
    <path d="M20.0189 17.751L38 19.0887V21.9106L20.0189 23.2483V17.751Z" fill="currentColor" />
    <path d="M19.9812 23.2483L2 21.9106V19.0887L19.9812 17.751V23.2483Z" fill="currentColor" />
  </Icon>
);
