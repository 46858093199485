import * as amplitude from '@amplitude/analytics-browser';

import { HeaderButtonName } from './types';

export type BlockShownName =
  | 'main'
  | 'benefits'
  | 'calculation'
  | 'insuranceCoverage'
  | 'documents'
  | 'faq';
export type FieldState = 'filled' | 'error' | 'empty';
export type Category = 'life' | 'lifeAndHome';
export type Status = 'success' | 'error';
export type CalculationData = {
  category: Category;
  status: Status;
};
export type CalculationPayData = CalculationData;
export type CalculationCostData = CalculationData & {
  bank_name: FieldState;
  mortgage_balance: FieldState;
  term_insurance: FieldState;
  term_insurance_start: FieldState;
  surname: FieldState;
  name: FieldState;
  patronymic: FieldState;
  birth_date: FieldState;
  phone_number: FieldState;
};
export type CalculationNextData = CalculationData & {
  interest_rate: FieldState;
  loan_agreement_number: FieldState;
  credit_contract_date: FieldState;
  email: FieldState;
  passport_number: FieldState;
  passport_issue_date: FieldState;
  authority: FieldState;
  birth_place: FieldState;
  birth_region: FieldState;
  registration_address: FieldState;
  property_region: FieldState;
  property_address: FieldState;
  cadastral_number: FieldState;
  area: FieldState;
};

export const trackBlockShown = (name: BlockShownName) => amplitude.track('block_shown', { name });
export const trackHeaderClick = (name: HeaderButtonName) =>
  amplitude.track('header_click', { button: name });
export const trackCalculateClick = () => amplitude.track('calculate_click');
export const trackCalculateCostClick = (data: CalculationCostData) => amplitude.track('calculation_calculateCost_click', data);
export const trackCalculationNextClick = (data: CalculationNextData) => amplitude.track('calculation_next_click', data);
export const trackCalculationFieldFilled = (name: string, value: FieldState) => amplitude.track('calculation_field_filled', { [name]: value  });
export const trackCalculationPayClick = (data: CalculationPayData) => amplitude.track('calculation_pay_click', data);
export const trackCalculatioPaymenSuccess = () => amplitude.track('calculation_payment_success');
export const trackDocumentClick = (name: string) =>
  amplitude.track('document_click', { document_name: name });
export const trackFaqQuestionOpen = (name: string) =>
  amplitude.track('faq_question_open', { question_name: name });
export const getTrackedFieldState = (
  value: string | number | Date,
  isInvalid: boolean
): FieldState => {
  if (isInvalid) {
    return 'error';
  }
  return value ? 'filled' : 'empty';
};
