import React, { FC, MouseEvent, useState } from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import type { InputProps } from '@chakra-ui/react';
import ru from 'date-fns/locale/ru'; // Import the locale data for Russian

import { Input } from '../../Input';
import { StyledContainer } from './styles';
import { Calendar } from '../../icons';

export interface Props extends Omit<ReactDatePickerProps, 'onChange' | 'value'> {
  hasLabel?: boolean;
  isInvalid?: boolean;
  fieldProps?: InputProps;
  onChange?: (date: Date) => void;
  value?: Date;
}

// TODO: календарь должен закрываться при выборе даты
export const Datepicker: FC<Props> = ({
  hasLabel,
  isInvalid,
  onChange,
  fieldProps,
  onClickOutside,
  onInputClick,
  maxDate,
  value,
  ...datepickerProps
}) => {
  const [startDate, setStartDate] = useState(value ?? new Date());
  const [isOpen, setIsOpen] = useState(false);

  const handleChange = (date: Date) => {
    setStartDate(date);
    onChange?.(date);
  };

  const handleClickOutside = (event: MouseEvent<HTMLDivElement>) => {
    setIsOpen(false);
    onClickOutside?.(event);
  };

  const handleInputClick = () => {
    setIsOpen(true);
    onInputClick?.();
  };

  return (
    <StyledContainer>
      <DatePicker
        selected={startDate}
        maxDate={maxDate}
        dateFormat="dd.MM.yyyy"
        placeholderText="дд.мм.гггг"
        locale={ru}
        customInput={
          <Input
            mask="99.99.9999"
            maskChar={null}
            hasLabel={hasLabel}
            isInvalid={isInvalid}
            {...fieldProps}
            data-focus-visible={isOpen || undefined}
            rightIcon={<Calendar color="greyNeptune" />}
          />
        }
        {...datepickerProps}
        onClickOutside={handleClickOutside}
        onInputClick={handleInputClick}
        onChange={handleChange}
      />
    </StyledContainer>
  );
};
