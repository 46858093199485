export const debounce = <F extends (...args: any) => any>(callback: F, wait: number) => {
  let timeoutId: NodeJS.Timeout | null = null;

  return (...args: Parameters<F>) => {
    if (timeoutId) clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      // @ts-ignore
      callback(...args);
    }, wait);
  };
};
