import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const QuestionMarkCircle: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM13 16V18H11V16H13ZM10 9C10 8.44772 10.4477 8 11 8H13C13.5523 8 14 8.44772 14 9V10C14 10.2761 13.7761 10.5 13.5 10.5C12.1193 10.5 11 11.6193 11 13V14H13V13C13 12.7239 13.2239 12.5 13.5 12.5C14.8807 12.5 16 11.3807 16 10V9C16 7.34315 14.6569 6 13 6H11C9.34315 6 8 7.34315 8 9H10Z"
      fill="currentColor"
    />
  </Icon>
);
