import { styled, Box } from '@chakra-ui/react';

import { theme } from '../../theme';

export const StyledContainer = styled(Box, {
  baseStyle: {
    '.react-datepicker-popper': { zIndex: '99999' },

    '.react-datepicker-wrapper': {
      width: '100%',
    },

    '.react-datepicker': {
      backgroundColor: 'greySun',
      width: '380px',
      padding: '24px 16px',
      borderRadius: '24px',
      border: 'none',
    },

    '.react-datepicker__header': {
      backgroundColor: 'greySun',
      borderBottom: 'none',
      paddingTop: 0,
    },

    '.react-datepicker__current-month': {
      color: 'greyPluton',
      ...theme.typography.h5,
      textTransform: 'capitalize',
      paddingBottom: '16px',
    },

    '.react-datepicker__day-names': {
      display: 'flex',
      alignItems: 'center',
      height: '48px',
    },

    '.react-datepicker__day-name': {
      color: 'greyNeptune',
      ...theme.typography.bodyM,
      width: '48px',
      margin: 0,
    },

    '.react-datepicker__day': {
      width: '48px',
      height: '48px',
      margin: 0,
      paddingTop: '14px',
      color: 'greyPluton',
      ...theme.typography.bodyM,
      ':hover': {
        borderRadius: '12px',
        backgroundColor: 'greySaturn',
      },
    },

    '.react-datepicker__day--outside-month': {
      color: 'greyUranus',
    },

    '.react-datepicker__day--today': {
      position: 'relative',
      '::after': {
        content: '""',
        width: '4px',
        height: '4px',
        backgroundColor: 'greyPluton',
        borderRadius: '50%',
        display: 'block',
        margin: '0 auto',
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
      },
    },

    '.react-datepicker__day--selected': {
      backgroundColor: 'brandAzure',
      color: 'greySun',
      borderRadius: '12px',
      ':hover': {
        color: 'greyPluton',
      },
    },

    '.react-datepicker__day--selected.react-datepicker__day--today': {
      '::after': {
        backgroundColor: 'greySun',
      },
      ':hover': {
        '::after': {
          backgroundColor: 'greyPluton',
        },
      },
    },

    '.react-datepicker__day--keyboard-selected': {
      backgroundColor: 'greySun',
    },

    '.react-datepicker__day--disabled': {
      color: 'greyUranus',
    },

    '.react-datepicker__triangle': {
      display: 'none',
    },

    '.react-datepicker__navigation': {
      marginTop: '24px',
      marginLeft: '16px',
      ':hover': {
        backgroundColor: 'greySaturn',
        borderRadius: '8px',
      },
    },

    '.react-datepicker__navigation--next': {
      marginRight: '16px',
    },

    '.react-datepicker__navigation-icon': {
      ':before': {
        borderColor: 'greyPluton',
      },
    },
  },
});
