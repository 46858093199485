import axios from 'axios';

import { createInfiniteQuery } from '../../../query';

export const useSuggests = createInfiniteQuery('suggests', {
  queryFn: async (params: { query: string }) => {
    // TODO Менять чтобы брали из env
    const domainName =
      window.location.href.includes('prod') || window.location.href.includes('rosbankinsurance')
        ? 'https://data-normalization-service.bestdoctor.ru/'
        : 'https://data-normalization-service.webholding-staging.ii.staging-00.bestdoctor.dev/';
    const URL = `${domainName}api/dadata/suggest/address/`;
    if (params.query && params.query.trim().length > 0) {
      const response = await axios.post<{ suggestions: { value: string }[] }>(URL, params);
      return { data: response.data };
    }

    return { data: [] };
  },
});
