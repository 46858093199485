import React from 'react';
import type { TextareaProps } from '@chakra-ui/react';
import { forwardRef, Textarea as TextareaChakra, InputGroup } from '@chakra-ui/react';

export interface Props extends TextareaProps {
  hasLabel?: boolean;
}

export const Textarea = forwardRef<Props, 'textarea'>(({ hasLabel, ...restProps }, ref) => (
  <InputGroup>
    <TextareaChakra
      maxLength={1024}
      zIndex="10"
      resize="none"
      borderRadius="16px"
      borderWidth="0"
      variant="filled"
      height={{ base: '112px', lg: '192px' }}
      p={hasLabel ? '30px 16px 16px' : '16px'}
      color="greyPluton"
      backgroundColor="greySaturn"
      errorBorderColor="brandMars"
      _placeholder={{ color: 'textSecondary' }}
      _focusVisible={{
        backgroundColor: 'greySun',
        borderColor: 'greyPluton',
        boxShadow: 'none',
        borderWidth: '1px',
        '&::placeholder': {
          color: 'greyNeptune',
        },
        ':hover': {
          backgroundColor: 'greySun',
        },
      }}
      _hover={{
        backgroundColor: 'greySaturn',
      }}
      sx={{
        '&[aria-invalid=true]': {
          boxShadow: 'none',
        },
        '&:focus::placeholder': {
          color: 'textSecondary',
        },
        '&::placeholder': {
          color: hasLabel ? 'transparent' : 'textSecondary',
          transition: 'color .3s',
        },
      }}
      {...restProps}
      ref={ref}
    />
  </InputGroup>
));
