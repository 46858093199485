import React from 'react';
import { Global } from '@emotion/react';

// TODO: заменить шрифты на лицензионные перед деплоем в продакшн
export const Fonts = () => (
  <Global
    styles={`
      @font-face {
        font-family: 'Muller';
        font-weight: 900;
        src: url('fonts/MullerBlack.ttf') format('truetype');
      }
      @font-face {
        font-family: 'Muller';
        font-weight: 700;
        src: url('fonts/MullerBold.ttf') format('truetype');
      }
       @font-face {
        font-family: 'Muller';
        font-weight: 400;
        src: url('fonts/MullerRegular.ttf') format('truetype');
      }
       @font-face {
        font-family: 'Muller';
        font-weight: 500;
        src: url('fonts/MullerMedium.ttf') format('truetype');
      }
    `}
  />
);
