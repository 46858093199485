import React, { cloneElement, FC } from 'react';
import { Tag as TagChakra, Stack } from '@chakra-ui/react';

import { Colors } from '../theme';
import { Text } from '../Text';
import { TagProps } from './types';
import { iconSizeVariables, styleSizeVariables } from './styles';

export const Tag: FC<TagProps> = ({
  size = 'md',
  label,
  labelColor,
  bgColor,
  tagVariant = 'solid',
  icon,
  textColor,
  ...rest
}) => {
  const color: Colors =
    bgColor === 'brandDeepBlue' || bgColor === 'brandMars' ? 'greySun' : 'greyPluton';

  return (
    <TagChakra
      bg={bgColor}
      borderRadius="40px"
      p={0}
      minW="max-content"
      variant={tagVariant}
      textColor={textColor ?? color}
      {...styleSizeVariables[size]}
      {...rest}
    >
      <Stack spacing={styleSizeVariables[size]?.spacing} direction="row" alignItems="center">
        {!!icon &&
          cloneElement(icon, {
            sx: { ...iconSizeVariables[size] },
          })}
        <Text
          as="span"
          color={labelColor ?? color}
          variant={styleSizeVariables[size]?.variant!}
          transition="color 0.3s"
        >
          {label}
        </Text>
      </Stack>
    </TagChakra>
  );
};
