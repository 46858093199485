import { AxiosResponseHeaders, RawAxiosResponseHeaders } from 'axios';

export type HeadersLike = string[][] | Record<string, string | undefined> | Headers;

export type UrlLike =
  | string
  | {
      href?: string;
      url?: string;
    };

export function createFetchHeaders(
  axiosHeaders?: RawAxiosResponseHeaders | AxiosResponseHeaders
): Record<string, string> {
  const headers: Record<string, string> = {};
  // @ts-ignore
  Object.entries(axiosHeaders).forEach(([name, value]) => {
    headers[name] = value;
  });
  return headers;
}

const isHeaders = (headers: HeadersLike): headers is Headers =>
  headers.constructor?.name === 'Headers';

export function createAxiosHeaders(headers: HeadersLike = {}): Record<string, string> {
  const rawHeaders: Record<string, string> = {};

  if (isHeaders(headers)) {
    headers.forEach((value, name) => {
      rawHeaders[name] = value;
    });
  } else if (Array.isArray(headers)) {
    headers.forEach(([name, value]) => {
      if (value) {
        rawHeaders[name] = value;
      }
    });
  } else {
    Object.entries(headers).forEach(([name, value]) => {
      if (value) {
        rawHeaders[name] = value;
      }
    });
  }
  return rawHeaders;
}

export function getUrl(input?: UrlLike): string | undefined {
  if (typeof input === 'string') return input;

  return input?.href || input?.url;
}
