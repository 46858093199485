import React from 'react';
import type { UseFormReturn, FieldValues } from 'react-hook-form';
import { FormProvider } from 'react-hook-form';

type SubmitHandler<TTransformedValues> = (
  data: TTransformedValues,
  event?: React.BaseSyntheticEvent,
  submitterName?: string
) => Promise<void> | void;

interface Props<
  TFieldValues extends FieldValues = FieldValues,
  TContext extends object = object,
  TTransformedValues extends FieldValues | undefined = undefined
> extends Omit<React.HTMLAttributes<HTMLFormElement>, 'onSubmit'> {
  value: UseFormReturn<TFieldValues, TContext>;
  onValid?: TTransformedValues extends FieldValues
    ? SubmitHandler<TTransformedValues>
    : SubmitHandler<TFieldValues>;
}

export const Form = <
  TFieldValues extends FieldValues,
  TContext extends object = object,
  TTransformedValues extends FieldValues | undefined = undefined
>({
  value: form,
  onValid,
  children,
  ...props
}: React.PropsWithChildren<Props<TFieldValues, TContext, TTransformedValues>>) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <FormProvider<TFieldValues, TContext> {...form}>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <form onSubmit={onValid ? form.handleSubmit(onValid) : undefined} autoComplete="off" {...props}>
      {children}
    </form>
  </FormProvider>
);

export { useFormContext } from 'react-hook-form';
