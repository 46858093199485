export const colors = {
  tag1: 'rgba(237, 235, 204, 0.40)',
  tag2: 'rgba(113, 215, 255, 0.24)',
  tag2OnColor: 'rgba(113, 215, 255, 0.88)',
  textSecondary: 'rgba(2, 83, 102, 0.60)',
  field: 'rgba(2, 107, 131, 0.04)',
  fieldHex: '#F6F9FA',
  buttonSecondary: {
    500: 'rgba(255, 115, 119, 0.32)',
  },
  tokensBorder: 'rgba(2, 107, 131, 0.24)',
  deepBlue: '#026B83',
  tokensField: 'rgba(0, 109, 134, 0.04)',

  brandMars: '#F22557',
  brandMarsLight: '#FFE3E4',
  brandMarsDark: '#D66164',
  brandProxima: '#078EFF',
  brandProximaLight: '#DDF5FF',
  brandSandyYellow: '#EDEBCC',
  brandDeepBlue: '#00274F',

  greyPluton: '#343540',
  greyNeptune: '#9496A1',
  greyUranus: '#D2D4DA',
  greySaturn: '#F6F8FA',
  greySun: '#FFFFFF',

  statusAndromedae: '#FC363D',
  statusAndromedaeLight: '#FFE7E8',
  statusSirius: '#FF9132',
  statusSiriusLight: '#FFF2E6',
  statusPolaris: '#32AF90',
  statusPolarisLight: '#DEF2ED',

  opacityStroke: '#01404F28',
  opacityOverlayDark: '#00000080',
  opacityOverlayLight: '#FFFFFFCC',

  lightBlock: '#F6F5E5',
};
