import React, { FC } from 'react';
import { TooltipProps, Tooltip as TooltipChakra } from '@chakra-ui/react';

import { theme } from '../theme';

export const Tooltip: FC<TooltipProps> = ({ children, ...rest }) => (
  <TooltipChakra
    hasArrow
    {...theme.typography.bodyM}
    borderRadius="10px"
    maxWidth={{ base: '328px', lg: '370px' }}
    bgColor="greyPluton"
    p="16px"
    pointerEvents="all"
    {...rest}
  >
    {children}
  </TooltipChakra>
);
