import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const ChevronDownIcon: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 14.2469L21.4132 5.5L22.5 6.48374L12.5 18.5L2.5 6.48374L3.5868 5.5L12.5 14.2469Z"
      fill="currentColor"
    />
  </Icon>
);
