import React, { FC } from 'react';
import { Text as TextChakra, TextProps } from '@chakra-ui/react';

import { Colors, Typography, theme } from '../theme';

export interface Props extends Omit<TextProps, 'variant' | 'color'> {
  color?: Colors;
  variant: Typography;
}

export const Text: FC<Props> = ({ children, color, variant, ...rest }) => (
  <TextChakra
    {...theme.typography[variant]}
    color={color}
    whiteSpace="pre-line"
    dangerouslySetInnerHTML={{ __html: Array.isArray(children) ? children.join('') : children }}
    {...rest}
  />
);
