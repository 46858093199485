import styled from '@emotion/styled';

export const Bullet = styled.div<{ isActive: boolean; progress: number; duration: number }>`
  position: relative;
  width: ${({ isActive }) => (isActive ? '48px' : '8px')};
  height: 8px;
  background-color: ${({ theme }: any) => theme.colors.greyUranus};
  border-radius: ${({ isActive }) => (isActive ? '20px' : '50%')};
  cursor: pointer;
  transition: all 0.5s;
  overflow: hidden;

  &:after {
    display: ${({ isActive }) => (isActive ? 'block' : 'none')};
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: ${({ progress }) => `${progress * 100}%`};
    background-color: ${({ theme }: any) => theme.colors.greyPluton};
    transition: width ${({ duration }) => `${duration}ms`};
  }
`;
