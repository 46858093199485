import React from 'react';
import { Checkbox as CheckboxChakra } from '@chakra-ui/react';

import { CheckboxProps } from './types';
import { CheckIcon, MinusIcon } from '../icons';

const COLORS = {
  error: 'brandMarsLight',
  default: 'greySun',
  checked: 'brandMars',
  disabled: 'brandMarsDark',
};

const getBgColor = ({
  error,
  checked,
  disabled,
  isBackgroundWhite,
}: {
  error: boolean;
  checked: boolean;
  disabled: boolean;
  isBackgroundWhite: boolean;
}) => {
  if (error) {
    return COLORS.error;
  }
  if (checked) {
    return COLORS.checked;
  }
  if (disabled) {
    return COLORS.disabled;
  }

  return isBackgroundWhite ? 'greySaturn' : COLORS.default;
};

export const Checkbox: React.FC<CheckboxProps> = ({
  checked,
  onChange,
  state = 'selected',
  error = false,
  disabled = false,
  borderRounded = false,
  isBackgroundWhite = false,
  ...rest
}) => {
  const bgColor = getBgColor({ error, checked, disabled, isBackgroundWhite });

  const icon =
    state === 'selected' ? (
      <CheckIcon w="20px" h="20px" color="greySaturn" />
    ) : (
      <MinusIcon w="20px" h="20px" color="greySaturn" />
    );

  return (
    <CheckboxChakra
      width="24px"
      checked={checked}
      onChange={onChange}
      height="24px"
      margin="4px"
      isDisabled={disabled}
      borderRadius={borderRounded ? '50%' : '6px'}
      bgColor={bgColor}
      _hover={{
        bg: 'auto',
      }}
      borderColor={bgColor}
      sx={{
        '& .chakra-checkbox__control': {
          width: '24px',
          height: '24px',
          borderRadius: borderRounded ? '50%' : '6px',
          padding: 0,
        },
        '& .chakra-checkbox__control[data-checked] ': {
          borderColor: '#F2F5F6',
          bgColor,
        },
        '& .chakra-checkbox__control[data-invalid] ': {
          borderColor: bgColor,
          bgColor,
        },
      }}
      icon={checked ? icon : undefined}
      {...rest}
    />
  );
};
