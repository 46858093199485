import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Discount: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 28 28" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.8133 2.65077C14.3525 2.22839 13.6452 2.22839 13.1844 2.65077L10.6375 4.98561L7.21344 4.85974C6.59469 4.837 6.0594 5.28671 5.97532 5.89992L5.50042 9.3635L2.78919 11.509C2.31002 11.8881 2.19068 12.5659 2.51153 13.0858L4.33979 16.0486L3.61912 19.4702C3.49256 20.0711 3.83845 20.671 4.42205 20.8628L7.68604 21.9351L9.29618 25.0198C9.58459 25.5723 10.247 25.8137 10.8235 25.5763L13.9989 24.2687L17.1743 25.5763C17.7507 25.8137 18.4131 25.5723 18.7016 25.0198L20.3117 21.9351L23.5757 20.8628C24.1593 20.671 24.5052 20.0711 24.3786 19.4702L23.6579 16.0486L25.4862 13.0858C25.8071 12.5659 25.6877 11.8881 25.2085 11.509L22.4973 9.3635L22.0224 5.89992C21.9383 5.28671 21.403 4.837 20.7843 4.85974L17.3602 4.98561L14.8133 2.65077ZM18.9501 9.0509C18.3806 8.48139 17.4572 8.48139 16.8877 9.0509L9.0505 16.888C8.48097 17.4575 8.48098 18.3809 9.0505 18.9504C9.62002 19.5199 10.5434 19.5199 11.1129 18.9504L18.9501 11.1133C19.5197 10.5438 19.5197 9.62042 18.9501 9.0509ZM19.8323 18.084C19.8323 19.0505 19.0488 19.834 18.0823 19.834C17.1157 19.834 16.3322 19.0505 16.3322 18.084C16.3322 17.1175 17.1157 16.334 18.0823 16.334C19.0488 16.334 19.8323 17.1175 19.8323 18.084ZM9.91547 11.6673C10.882 11.6673 11.6655 10.8838 11.6655 9.91732C11.6655 8.95082 10.882 8.16732 9.91547 8.16732C8.94896 8.16732 8.16545 8.95082 8.16545 9.91732C8.16545 10.8838 8.94896 11.6673 9.91547 11.6673Z"
      fill="white"
    />
  </Icon>
);
