import React, { FC } from 'react';

import { FormControl, type Props as FormControlProps } from '../../FormControl';
import { RadioGroup, RadioGroupProps } from '../../RadioGroup';

export type Props = RadioGroupProps &
  Pick<
    FormControlProps,
    'label' | 'legend' | 'description' | 'isInvalid' | 'isDisabled' | 'isRequired' | 'errorText'
  >;

export const FormRadioGroup: FC<Props> = ({
  label,
  legend,
  description,
  isInvalid,
  isDisabled,
  isRequired,
  errorText,
  ...radioGroupProps
}) => (
  <FormControl
    label={label}
    legend={legend}
    description={description}
    isInvalid={isInvalid}
    errorText={errorText}
    isDisabled={isDisabled}
    isRequired={isRequired}
    hasValue={!!radioGroupProps.value}
  >
    <RadioGroup {...radioGroupProps} />
  </FormControl>
);
