import { ReactElement, useState } from 'react';

export interface UseMultistepFormResult {
  currentStepIndex: number;
  step: ReactElement;
  steps: ReactElement[];
  isFirstStep: boolean;
  isLastStep: boolean;
  goTo: (index: number) => void;
  next: VoidFunction;
  back: VoidFunction;
}

export const useMultistepForm = (steps: ReactElement[]): UseMultistepFormResult => {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  const next = () => {
    setCurrentStepIndex((index) => index + 1);
  };

  const back = () => {
    setCurrentStepIndex((index) => index - 1);
  };

  const goTo = (index: number) => {
    setCurrentStepIndex(index);
  };

  return {
    currentStepIndex,
    step: steps[currentStepIndex],
    steps,
    isFirstStep: currentStepIndex === 0,
    isLastStep: currentStepIndex === steps.length - 1,
    goTo,
    next,
    back,
  };
};
