import React, { FC, useState } from 'react';
import {
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionItemProps,
} from '@chakra-ui/react';

import { trackFaqQuestionOpen } from '@/shared/lib';

import { MinusIcon, PlusIcon } from '../icons';
import { Text } from '../Text';

interface Props extends AccordionItemProps {
  title: string;
  description: string;
}

export const AccordionData: FC<Props> = ({ title, description, ...rest }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleButtonClick = () => {
    trackFaqQuestionOpen(title);
    setIsOpen(!isOpen);
  };

  return (
    <AccordionItem
      {...rest}
      borderWidth={1}
      borderStyle="solid"
      borderColor={isOpen ? 'brandMars' : 'tokensBorder'}
      borderRadius="24px"
      mb={6}
      cursor="pointer"
      _hover={{
        borderColor: 'brandMars',
      }}
    >
      <AccordionButton
        display="flex"
        justifyContent="space-between"
        gap="16px"
        pt={{ base: 4, lg: 6 }}
        px={{ base: 4, lg: 6 }}
        pb={{ base: isOpen ? '12px' : '16px', lg: isOpen ? '20px' : '24px' }}
        _hover={{
          backgroundColor: 'none',
        }}
        onClick={handleButtonClick}
      >
        <Text as="h3" variant="h5" color="greyPluton" textAlign="left">
          {title}
        </Text>
        {isOpen ? (
          <MinusIcon
            color="brandMars"
            width={{ base: '24px', lg: '40px' }}
            height={{ base: '24px', lg: '40px' }}
          />
        ) : (
          <PlusIcon
            color="brandMars"
            width={{ base: '24px', lg: '40px' }}
            height={{ base: '24px', lg: '40px' }}
          />
        )}
      </AccordionButton>
      <AccordionPanel pb={{ base: 4, lg: 6 }} pl={{ base: 4, lg: 6 }} pt={0}>
        <Text variant="bodyS" color="greyPluton" maxWidth="978px">
          {description}
        </Text>
      </AccordionPanel>
    </AccordionItem>
  );
};
