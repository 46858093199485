import React, { FC, useState } from 'react';
import {
  ModalHeader,
  IconButton,
  ModalBody,
  StackProps,
  useDisclosure,
  HStack,
  ModalContent,
  Modal,
  Link,
} from '@chakra-ui/react';

import { CloseIcon, LogoIconMob, HamburgerIcon } from '../icons';
import { MobileMenu } from './MobileMenu';

interface Props extends StackProps {
  isSticky?: boolean;
  onOpenInsuranceCase: VoidFunction;
}

export const MobileNav: FC<Props> = ({ isSticky, onOpenInsuranceCase, ...restProps }) => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure({ id: 'menu-drawer' });

  const handleOpenMenu = () => {
    setIsOpenMenu(true);
    onOpen();
  };

  const handleClose = () => {
    setIsOpenMenu(false);
    onClose();
  };

  const handleOpenInsuranceCase = () => {
    onClose();
    onOpenInsuranceCase();
  };

  return (
    <>
      <HStack
        alignItems="center"
        spacing="0"
        ml="auto"
        mt={{ lg: isSticky ? '22px' : '0' }}
        {...restProps}
      >
        <IconButton
          aria-label="Открыть меню"
          icon={<HamburgerIcon width="20px" height="20px" />}
          colorScheme="greySun"
          color={isSticky ? 'greyPluton' : 'greySun'}
          variant="solid"
          width="52px"
          height={{ base: '52px', lg: '96px' }}
          onClick={handleOpenMenu}
        />
      </HStack>

      {isOpen && (
        <Modal size="full" motionPreset="none" isOpen={isOpen} onClose={handleClose}>
          <ModalContent>
            <IconButton
              aria-label="Закрыть меню"
              icon={<CloseIcon width="16px" height="16px" />}
              onClick={handleClose}
              position="absolute"
              top="0"
              right="0"
              cursor="pointer"
              colorScheme="greySun"
              color="brandDeepBlue"
            />

            <ModalHeader py="12px" px="16px">
              <Link href="https://rosbankinsurance.ru/">
                <LogoIconMob ml="32px" />
              </Link>
            </ModalHeader>
            <ModalBody py="12px" px="16px">
              {isOpenMenu && <MobileMenu onOpenInsuranceCase={handleOpenInsuranceCase} />}
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};
