import { SizeVariables } from './types';

export const styleSizeVariables: SizeVariables = {
  md: {
    alignItems: 'center',
    padding: { base: '10px 16px 10px 12px', lg: '17px 24px 17px 20px' },
    variant: 'bodyM',
    spacing: { base: '6px', lg: '8px' },
  },
  sm: {
    alignItems: 'center',
    padding: { base: '7px 12px 7px 10px', lg: '10px 16px 10px 12px' },
    variant: 'bodyS',
    spacing: '6px',
  },
  xs: {
    alignItems: 'center',
    padding: { base: '4px 10px 4px 8px', lg: '7px 12px 7px 10px' },
    variant: 'caption',
    spacing: { base: '4px', lg: '6px' },
  },
};

export const iconSizeVariables: SizeVariables = {
  md: {
    width: { base: '20px', lg: '24px' },
    height: { base: '20px', lg: '24px' },
  },
  sm: {
    width: { base: '16px', lg: '20px' },
    height: { base: '16px', lg: '20px' },
  },
  xs: {
    width: { base: '12px', lg: '16px' },
    height: { base: '12px', lg: '16px' },
  },
};
