import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const HeartIcon: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 40 40" {...props}>
    <path
      d="M23.5056 33.75C21.4455 35.3348 20 36.25 20 36.25C20 36.25 2.5 25.1705 2.5 13.3523C2.5 8.55114 6.14583 3.75 11.25 3.75C15.3333 3.75 18.7847 6.21212 20 7.44318C21.2153 6.21212 24.6667 3.75 28.75 3.75C33.8542 3.75 37.5 8.55114 37.5 13.3523C37.5 15.4 36.9746 17.4255 36.106 19.375"
      stroke="#343540"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.5 24.375H22.5V29.375H27.5V34.375H32.5V29.375H37.5V24.375H32.5V19.375H27.5V24.375Z"
      stroke="#F22557"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
