import type { UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import { queryClient } from './provider';
import type {
  ReturnedQueryHook,
  SingleQueryFn,
  SingleQueryFnData,
  SetQueryData,
  BaseResponse,
} from './types';

export type Options<
  TQueryFn extends SingleQueryFn = SingleQueryFn,
  TQueryFnData extends SingleQueryFnData<TQueryFn>['data'] = SingleQueryFnData<TQueryFn>['data'],
  TError = unknown,
  TData extends BaseResponse<any>['data'] = TQueryFnData
> = Omit<UseQueryOptions<TQueryFnData, TError, TData>, 'queryKey' | 'queryFn'> & {
  queryFn: TQueryFn;
  secondaryKey?: string;
};

export type Result<
  TPrimaryKey extends string,
  TQueryFn extends SingleQueryFn = SingleQueryFn,
  TQueryFnData extends SingleQueryFnData<TQueryFn>['data'] = SingleQueryFnData<TQueryFn>['data'],
  TData extends BaseResponse<any>['data'] = TQueryFnData,
  TError = unknown
> = Omit<UseQueryResult<TData, TError>, 'data'> & {
  [P in TPrimaryKey]: TData;
};

const getQueryKey = (initialKey: string[]) => (params?: unknown) =>
  params ? [...initialKey, params] : initialKey;

export type HookOptions<
  TQueryFn extends SingleQueryFn = SingleQueryFn,
  TQueryFnData extends SingleQueryFnData<TQueryFn>['data'] = SingleQueryFnData<TQueryFn>['data'],
  TError = unknown,
  TData extends BaseResponse<any>['data'] = TQueryFnData
> = Pick<Options<TQueryFn, TQueryFnData, TError, TData>, 'enabled' | 'refetchOnMount'>;

export const createSingleQuery = <
  TQueryFn extends SingleQueryFn = SingleQueryFn,
  TParams extends Parameters<TQueryFn>[0] = Parameters<TQueryFn>[0],
  TQueryFnData extends SingleQueryFnData<TQueryFn>['data'] = SingleQueryFnData<TQueryFn>['data'],
  TData extends BaseResponse<any>['data'] = TQueryFnData,
  TError = unknown,
  TPrimaryKey extends string = any
>(
  primaryKey: TPrimaryKey,
  { queryFn, secondaryKey, ...options }: Options<TQueryFn, TQueryFnData, TError, TData>
): ReturnedQueryHook<
  TParams,
  HookOptions<TQueryFn, TQueryFnData, TError, TData>,
  TData,
  Result<TPrimaryKey, TQueryFn, TQueryFnData, TData, TError>
> => {
  const queryKey = secondaryKey ? [primaryKey, secondaryKey] : [primaryKey];
  const addParamsToKeyIfExist = getQueryKey(queryKey);

  const useHook = (
    params?: TParams,
    hookOptions?: HookOptions<TQueryFn, TQueryFnData, TError, TData>
  ) => {
    const { data, ...query } = useQuery<TQueryFnData, TError, TData>({
      ...options,
      ...hookOptions,
      queryKey: addParamsToKeyIfExist(params),
      queryFn: async () => (await queryFn(params)).data,
    });

    return {
      ...query,
      [primaryKey]: data,
    } as Result<TPrimaryKey, TQueryFn, TQueryFnData, TData, TError>;
  };

  useHook.invalidateQuery = (params?: TParams) => {
    queryClient.invalidateQueries({ queryKey: addParamsToKeyIfExist(params) });
  };

  const setQueryData: SetQueryData<TParams, TData> = (params, dataOrFn) => {
    queryClient.setQueryData(addParamsToKeyIfExist(params), dataOrFn);
  };

  useHook.setQueryData = setQueryData;

  useHook.fetchQuery = (params?: TParams) =>
    queryClient.fetchQuery({
      ...options,
      queryKey: addParamsToKeyIfExist(params),
      queryFn: async () => (await queryFn(params)).data,
    });

  useHook.getQueryData = (params?: TParams) =>
    queryClient.getQueryData<TQueryFnData>(addParamsToKeyIfExist(params));

  return useHook;
};
