import React, { FC } from 'react';
import {
  Show,
  Hide,
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalProps,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  ModalContentProps,
  DrawerContentProps,
  ModalBodyProps,
  SimpleGrid,
  useBreakpointValue,
} from '@chakra-ui/react';

import { ChevronLeftIcon } from '../../icons';
import { CloseButton } from '../../CloseButton';
import { Button } from '../../Button';

interface Props extends ModalProps {
  modalContent?: ModalContentProps;
  drawerContent?: DrawerContentProps;
  drawerBody?: ModalBodyProps;
  onBack?: VoidFunction;
  isCloseButtonActive?: boolean;
}

export const Modal: FC<Props> = ({
  children,
  isOpen,
  onClose,
  onBack,
  modalContent,
  drawerContent,
  drawerBody,
  isCloseButtonActive = true,
  ...restProps
}) => {
  const isMobileScreen = useBreakpointValue({ base: true, lg: false });

  return (
    <>
      <Show above="lg">
        <ChakraModal isOpen={isOpen} onClose={onClose} isCentered {...restProps}>
          <ModalOverlay />
          <ModalContent minW="fit-content" borderRadius="32px" p="32px" {...modalContent}>
            <CloseButton
              onClose={onClose}
              position="absolute"
              top="0"
              left="calc(100% + 8px)"
              zIndex="10"
            />
            {children}
          </ModalContent>
        </ChakraModal>
      </Show>
      <Hide above="lg">
        <Drawer
          isOpen={isOpen}
          onClose={onClose}
          placement="bottom"
          scrollBehavior="inside"
          {...restProps}
        >
          <DrawerOverlay />
          <DrawerContent
            h={{ base: 'calc(100dvh - 32px)', lg: 'auto' }}
            maxH="calc(100dvh - 32px)"
            maxW={{ lg: 'calc(100vw - 64px) !important' }}
            left={{ lg: '32px !important' }}
            right={{ lg: '32px !important' }}
            borderRadius="32px 32px 0 0"
            overflow="hidden"
            {...drawerContent}
          >
            <DrawerBody
              position="relative"
              p={isMobileScreen && onBack ? '16px' : '48px 16px 16px'}
              overflowX="hidden"
              _before={{
                content: '""',
                position: 'absolute',
                top: '12px',
                left: 'calc(50% - 16px)',
                zIndex: '10',
                w: '32px',
                h: '4px',
                bgColor: 'greyUranus',
                borderRadius: '3px',
              }}
              {...drawerBody}
            >
              {isCloseButtonActive && (
                <CloseButton
                  onClose={onClose}
                  position="fixed"
                  top={{ base: '4px', lg: '10px' }}
                  right={{ base: '4px', lg: '10px' }}
                  zIndex="100"
                />
              )}
              {isMobileScreen && onBack ? (
                <SimpleGrid gap="8px">
                  {isMobileScreen && onBack && (
                    <Button
                      onClick={onBack}
                      variant="pseudo"
                      size="md"
                      text="Назад"
                      left
                      icon={<ChevronLeftIcon />}
                      p="0"
                      color="greyPluton"
                      _hover={{ color: 'greyPluton', bgColor: 'transparent' }}
                    />
                  )}
                  {children}
                </SimpleGrid>
              ) : (
                children
              )}
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Hide>
    </>
  );
};
