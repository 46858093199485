import { extendTheme } from '@chakra-ui/react';

import { typography, colors, breakpoints } from './base';

export const theme = extendTheme({
  colors,
  typography,
  breakpoints,
  fonts: {
    heading: 'Muller',
    body: 'Muller',
  },
  styles: {
    global: {
      body: {
        color: colors.greyPluton,
        ...typography.bodyS,
      },
    },
  },
});

export * from './base';
