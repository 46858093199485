import React, { FC } from 'react';

import { FormFileUploader, FileUploaderProps } from '@/shared/ui';

import { useField } from '../lib';
import type { FormControlProps } from './types';

export type Props = Omit<FileUploaderProps, 'onDrop' | 'onSelect'> & FormControlProps;

export const FileUploader: FC<Props> = ({ label, isRequired, ...props }) => {
  const { error, invalid, onChange, value, ...field } = useField<Record<string, any>>({
    name: props.name ?? '',
  });

  return (
    <FormFileUploader
      label={label}
      isInvalid={invalid}
      errorText={error}
      isRequired={isRequired}
      {...props}
      onChange={onChange}
      value={value ?? []}
      {...field}
    />
  );
};
