import React, { useMemo, useState, FC, RefObject, useEffect } from 'react';
import { FormHelperText } from '@chakra-ui/react';

import { mapListToOptions } from '../../../form';
import { debounce, removeDuplicatesFromArray } from '../../../lib';
import { FormControl, type Props as FormControlProps } from '../../FormControl';
import { Select, type SelectProps } from '../../Select';
import { useSuggests } from '../api/api';
import { Option } from '../../Suggest';
import { STOP_REGION_LIST, STOP_REGION_LIST_FOR_FLAT } from './mockData';

export type Props = Omit<
  SelectProps,
  'onSelect' | 'selected' | 'options' | 'onInputChange' | 'value'
> &
  Pick<FormControlProps, 'label' | 'errorText' | 'isInvalid' | 'legend' | 'isRequired'> & {
    value: string;
    defaultCity?: string;
  } & {
    isRegion?: boolean;
    isFlatAddress?: boolean;
    selectedRegion?: string;
    hint?: string;
  };

export const FormAddressInput: FC<Props> = ({
  label,
  legend,
  isInvalid,
  isDisabled,
  isRequired,
  errorText,
  defaultCity,
  onChange,
  isRegion = false,
  isFlatAddress = false,
  selectedRegion,
  hint,
  ...selectProps
}) => {
  const [inputElement, setInputElement] = useState<HTMLInputElement | null | undefined>(null);
  const [query, setQuery] = useState(selectProps.value ?? '');
  const [selectedItem, setSelectedItem] = useState<Option | null>(
    selectProps.value ? { value: selectProps.value, label: selectProps.value } : null
  );

  const { suggests } = useSuggests({
    query: defaultCity ? `г ${defaultCity} ${query}` : query,
  });

  // @ts-ignore
  const filteredSuggestionsForAddress = suggests?.filter(
    (suggestion: { data: { region_with_type: string | string[] } }) =>
      !STOP_REGION_LIST.some((region) => suggestion.data.region_with_type.includes(region))
  );

  const filteredSuggestionsForFlat = suggests?.filter(
    (suggestion: { data: { region_with_type: string | string[] } }) =>
      !STOP_REGION_LIST_FOR_FLAT.some((region) => suggestion.data.region_with_type.includes(region))
  );

  const filteredSuggestions = isFlatAddress
    ? filteredSuggestionsForFlat
    : filteredSuggestionsForAddress;

  const addressesWithoutRegion = filteredSuggestions?.filter(
    (item: { data: { region_with_type: string | undefined } }) =>
      item.data.region_with_type === selectedRegion
  );

  const regions = filteredSuggestions?.map((item: any) => ({
    ...item,
    value: item.data.region_with_type,
  }));

  const address = isRegion ? regions : addressesWithoutRegion;
  const options = useMemo(
    () =>
      mapListToOptions(
        removeDuplicatesFromArray(address, 'value').filter((item) => item.value),
        'value',
        'value'
      ),
    [suggests, address]
  );

  const handleAddressChange = debounce((value: string, inputRef?: RefObject<HTMLInputElement>) => {
    setInputElement(inputRef?.current);
    setQuery(value);
    onChange?.('');
  }, 600);

  const handleSelect = (option: Option) => {
    if (option) {
      setSelectedItem(option);
      setQuery(option.value as string);
      onChange?.(option.value as string);
    }
  };

  // // TODO: хак для установки фокуса после обновления списка, разобраться и исправить, чтобы убрать
  useEffect(() => {
    setTimeout(() => {
      inputElement?.focus();
    }, 200);
  }, [suggests]);

  return (
    <FormControl
      label={label}
      legend={legend}
      isInvalid={isInvalid}
      errorText={errorText}
      isDisabled={isDisabled}
      isRequired={isRequired}
      hasValue={Boolean(selectProps.value) || Boolean(query)}
    >
      <Select
        isAddress
        options={options}
        onInputChange={handleAddressChange}
        clearable={false}
        onChange={onChange}
        {...selectProps}
        selected={
          selectProps.value ? { value: selectProps.value, label: selectProps.value } : selectedItem
        }
        onSelect={handleSelect}
        fieldProps={{ ...selectProps.fieldProps, hasLabel: Boolean(label) }}
      />
      {hint && !errorText && !selectedRegion && (
        <FormHelperText color="greyNeptune">{hint}</FormHelperText>
      )}
    </FormControl>
  );
};
