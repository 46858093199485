import {
  productsFactory,
  searchFactory,
  insuranceCasesFactory,
  geoFactory,
  purchasesFactory,
} from '@bestdoctor/mitra-api';

import Config from '@/config.json';

import { httpClient } from '../lib';
import { buildAxiosFetch } from './axiosToFetch';

const makeRequest = buildAxiosFetch(httpClient);

const apiUrl = new URL(Config.REACT_APP_API_HOST || Config.REACT_APP_API_HOST_LOCAL);
export const API_HOST = `${apiUrl.protocol}//${apiUrl.host}`;

export const acceptHeader = 'application/vnd.mitra.v1-common';

export type AcceptHeader = typeof acceptHeader;

const baseApiConfiguration = {
  host: API_HOST,
  makeRequest,
  headers: {
    accept: acceptHeader,
  },
};

const usedApis = {
  [productsFactory.apiName]: productsFactory,
  [searchFactory.apiName]: searchFactory,
  [insuranceCasesFactory.apiName]: insuranceCasesFactory,
  [geoFactory.apiName]: geoFactory,
  [purchasesFactory.apiName]: purchasesFactory,
};

export type ApiFactoryNamesType = keyof typeof usedApis;

export type ApiContextType = {
  [apiName in ApiFactoryNamesType]: ReturnType<(typeof usedApis)[apiName]>;
};

export const api = (Object.keys(usedApis) as ApiFactoryNamesType[]).reduce(
  (result, apiName) => {
    // @ts-expect-error траблы с типами в пакете
    const currentApi = usedApis[apiName](baseApiConfiguration);

    return {
      ...result,
      [apiName]: currentApi,
    };
  },
  // каст - нехорошо, но нормально типизировать reduce не выходит - дефолтом требует выходной тип
  {} as ApiContextType
);

export * from './types';
