import React, { FC } from 'react';

import { FormAddressInput, type AddressInputProps } from '@/shared/ui';

import { useField } from '../lib';
import type { FormControlProps } from './types';

export type Props = Omit<AddressInputProps, 'value' | 'onChange'> &
  FormControlProps & { selectedValue?: string };

export const AddressInput: FC<Props> = (props) => {
  const { error, invalid, value, ...field } = useField<Record<string, any>>({
    onBlur: props.onBlur,
    name: props.name ?? '',
  });

  return (
    <FormAddressInput
      isInvalid={invalid}
      errorText={error}
      hint={props.hint}
      value={props.selectedValue ? props.selectedValue : value}
      {...props}
      {...field}
      onChange={(option) => field.onChange(option)}
    />
  );
};
