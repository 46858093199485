import { Breadcrumb as BreadcrumbChakra, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react';
import React, { FC } from 'react';

import { Text } from '../Text';

interface ParentPages {
  title: string;
  href: string;
}
interface Props {
  title: string;
  parentPages?: ParentPages[];
}

export const Breadcrumb: FC<Props> = ({ title, parentPages }) => {
  const itemsCount = parentPages ? parentPages.length + 1 : 1;
  return (
    <BreadcrumbChakra my={6}>
      {parentPages?.map(({ title: pageTitle, href }) => (
        <BreadcrumbItem>
          <BreadcrumbLink href={href}>
            <Text
              maxW={{
                base: (window.innerWidth - 104 - itemsCount * 22) / itemsCount,
                sm: (window.innerWidth - 125 - itemsCount * 22) / itemsCount,
                lg: '300px',
              }}
              variant="caption"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              display="block"
              wordBreak="break-word"
              isTruncated
            >
              {pageTitle}
            </Text>
          </BreadcrumbLink>
        </BreadcrumbItem>
      ))}

      <BreadcrumbItem isCurrentPage color="brandDeepBlue" opacity="60%">
        <BreadcrumbLink href="#">
          <Text
            maxW={{
              base: (window.innerWidth - 104 - itemsCount * 22) / itemsCount,
              sm: (window.innerWidth - 125 - itemsCount * 22) / itemsCount,
              lg: '300px',
            }}
            variant="caption"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            display="block"
            wordBreak="break-word"
            isTruncated
          >
            {title}
          </Text>
        </BreadcrumbLink>
      </BreadcrumbItem>
    </BreadcrumbChakra>
  );
};
