import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const TelephoneIcon: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 20 20" {...props}>
    <g clipPath="url(#clip0_12156_100039)">
      <path
        d="M15 10C15 7.23858 12.7614 5 10 5"
        stroke="#5B5D6B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3333 9.99996C18.3333 5.39759 14.6024 1.66663 10 1.66663"
        stroke="#5B5D6B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.91083 6.60761L4.3181 4.01488C3.96012 3.6569 3.37314 3.66348 3.00704 4.02959L1.25075 5.78587C1.10023 5.93639 1.00212 6.13135 0.988125 6.34167C0.881532 7.94359 1.22807 11.5319 4.84845 15.1518C8.49825 18.8012 11.843 19.127 13.6471 19.0098C13.8638 18.9958 14.0669 18.8969 14.222 18.7419L15.9707 16.9932C16.3368 16.6271 16.3433 16.0401 15.9854 15.6821L13.3926 13.0894C13.0347 12.7314 12.4477 12.738 12.0816 13.1041L10.9564 14.2293C10.8256 14.3601 10.6594 14.4509 10.4764 14.4536C9.92754 14.4615 8.63354 14.2972 7.16832 12.832C5.70311 11.3667 5.53871 10.0727 5.54669 9.52383C5.54935 9.34085 5.64017 9.17463 5.77099 9.0438L6.89612 7.91867C7.26222 7.55257 7.26881 6.96559 6.91083 6.60761Z"
        stroke="#5B5D6B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_12156_100039">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </Icon>
);
