import { styled, Card } from '@chakra-ui/react';

export const StyledCard = styled(Card, {
  baseStyle: {
    borderRadius: '24px',
    boxShadow: 'none',
    border: '1px solid rgba(2, 107, 131, 0.24)',
    ':hover': {
      border: '1px solid',
      borderColor: 'brandMars',
      cursor: 'pointer',
    },
  },
});
