export const STOP_REGION_LIST = [
  'Запорожская обл',
  'Донецкая Народная респ',
  'Луганская Народная респ',
  'Херсонская обл',
];

export const STOP_REGION_LIST_FOR_FLAT = [
  'Запорожская обл',
  'Донецкая Народная респ',
  'Луганская Народная респ',
  'Херсонская обл',
  'Белгородская обл',
  'Брянская обл',
  'Курская обл',
];
