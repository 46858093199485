import React from 'react';

import { SingleSuggest } from './SingleSuggest';
import { MultipleSuggest } from './MultipleSuggest';
import { SuggestProps } from './types';

export const Suggest: React.FC<SuggestProps> = ({
  options,
  onSelect,
  onChange,
  selected,
  isOptionsLoading,
  isMultiple = false,
}) =>
  isMultiple ? (
    <MultipleSuggest
      selected={selected}
      options={options}
      onSelect={onSelect}
      isOptionsLoading={isOptionsLoading}
      onChange={onChange}
    />
  ) : (
    <SingleSuggest
      options={options}
      onSelect={onSelect}
      isOptionsLoading={isOptionsLoading}
      selected={selected || { label: '', value: '' }}
      onChange={onChange}
    />
  );
