const loadScript = (src: string) =>
  new Promise<void>((resolve, reject) => {
    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    script.onload = () => resolve();
    script.onerror = () => reject(new Error(`Failed to load script ${src}`));
    document.body.appendChild(script);
  });

export const loadBviScripts = async () => {
  try {
    await loadScript('https://lidrekon.ru/slep/js/jquery.js');
    await loadScript('https://lidrekon.ru/slep/js/uhpv-hover-full.min.js');
  } catch (error) {
    console.error(error);
  }
};
