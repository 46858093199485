import React, { ReactElement } from 'react';
import InputMask from 'react-input-mask';
import type { InputProps as InputBaseProps } from '@chakra-ui/react';
import {
  forwardRef,
  Input as InputChakra,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react';

export interface Props extends InputBaseProps {
  leftIcon?: ReactElement;
  rightIcon?: ReactElement;
  hasLabel?: boolean;
  mask?: string | Array<string | RegExp>;
  clearable?: boolean;
}

export const Input = forwardRef<Props, 'input'>(
  ({ rightIcon, leftIcon, hasLabel, isInvalid, mask, ...props }, ref) => (
    <InputGroup>
      {leftIcon && (
        <InputLeftElement height="100%" ml={{ base: '16px', lg: '20px' }} userSelect="none">
          {leftIcon}
        </InputLeftElement>
      )}
      <InputChakra
        as={mask ? InputMask : InputChakra}
        mask={mask}
        borderRadius={{ base: '12px', lg: '16px' }}
        borderWidth="1px"
        variant="filled"
        height={{ base: '56px', lg: '64px' }}
        pt={hasLabel ? '22px' : '0'}
        pl={{ base: leftIcon ? '56px' : '16px', lg: leftIcon ? '60px' : '20px' }}
        color="greyPluton"
        backgroundColor="greySaturn"
        borderColor="transparent"
        errorBorderColor="statusAndromedae"
        _hover={{
          backgroundColor: 'greySaturn',
          borderColor: isInvalid ? 'statusAndromedae' : 'transparent',
        }}
        _focusVisible={{
          backgroundColor: 'greySun',
          borderColor: 'greyPluton',
          boxShadow: 'none',
          '&::placeholder': {
            color: 'greyNeptune',
          },
        }}
        sx={{
          '&[aria-invalid=true]': {
            boxShadow: 'none',
          },
          '&:focus::placeholder': {
            color: 'greyNeptune',
          },
          '&::placeholder': {
            color: hasLabel ? 'transparent' : 'greyNeptune',
            transition: 'color .3s',
          },
        }}
        {...props}
        ref={ref}
      />
      {rightIcon && (
        <InputRightElement
          height="100%"
          mr={{ base: '16px', lg: '20px' }}
          userSelect="none"
          width="auto"
        >
          {rightIcon}
        </InputRightElement>
      )}
    </InputGroup>
  )
);
