import React, { FC, cloneElement } from 'react';
import { Button as ButtonChakra, Spinner } from '@chakra-ui/react';

import { ButtonProps } from './type';
import { colorVariantVariables, iconSizeVariables, styleSizeVariables } from './styles';
import { Text } from '../Text';
import { capitalize } from '../../lib';
import { Typography } from '../theme';

export const Button: FC<ButtonProps> = ({
  disabled,
  size,
  text,
  variant,
  loading,
  icon,
  left,
  borderRadius = '16px',
  ...rest
}) => (
  <ButtonChakra
    isDisabled={disabled || loading}
    width="max-content"
    alignItems="center"
    borderRadius={borderRadius}
    display="flex"
    flexDirection={left ? 'row' : 'row-reverse'}
    bg={colorVariantVariables[variant].default}
    size={size}
    textColor={colorVariantVariables[variant].textColor}
    _hover={{
      bg: !(disabled || loading) && colorVariantVariables[variant].hover,
      color: !(disabled || loading) && colorVariantVariables[variant].textColorHover,
    }}
    {...styleSizeVariables[size]}
    {...rest}
  >
    {loading && (
      <Spinner {...iconSizeVariables[size]} color={colorVariantVariables[variant].textColor} />
    )}
    {!loading && (icon ? cloneElement(icon, { ...iconSizeVariables[size] }) : null)}
    {text && (
      <Text
        as="span"
        minWidth="0"
        textOverflow="ellipsis"
        overflow="hidden"
        whiteSpace="nowrap"
        title={capitalize(text)}
        variant={styleSizeVariables[size].textVariant as Typography}
      >
        {capitalize(text)}
      </Text>
    )}
  </ButtonChakra>
);
