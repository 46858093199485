import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { SwiperProps } from 'swiper/swiper-react';

interface Props<Slide> extends SwiperProps {
  slides: Slide[];
  renderSlide: (slide: Slide) => React.ReactElement;
  getSlideKey: (slide: Slide) => string | number;
}

// TODO: разобраться со стилями '.swiper-wrapper, .swiper-slide' - попробовать отрефакторить от них избавиться
export const Carousel = <Slide = any,>({
  slides,
  renderSlide,
  getSlideKey,
  ...rest
}: Props<Slide>) => (
  <Swiper mousewheel keyboard {...rest}>
    {slides.map((slide) => (
      <SwiperSlide key={getSlideKey(slide)}>{renderSlide(slide)}</SwiperSlide>
    ))}
  </Swiper>
);
