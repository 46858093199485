import React from 'react';
import { createBrowserRouter } from 'react-router-dom';

const MortgagePage = React.lazy(() => import('../pages/mortgagePage'));
const MortgageForm2Step = React.lazy(() => import('../pages/mortgageForm2Step'));
const MortgageConfirmPage = React.lazy(() => import('../pages/mortgageConfirmPage'));
const PurchaseSuccessPage = React.lazy(() => import('../pages/purchaseSuccessPage'));
const NewsPage = React.lazy(() => import('../pages/newsPage'));

const getRoutes = () => [
  {
    errorElement: <div>Error page</div>,
    children: [
      {
        path: '/',
        element: <MortgagePage />,
      },
      {
        path: '/form',
        element: <MortgageForm2Step />,
      },
      {
        path: '/confirm',
        element: <MortgageConfirmPage />,
      },
      {
        path: '/success',
        element: <PurchaseSuccessPage />,
      },
      {
        path: '/news-mortgage-insurance-policy',
        element: <NewsPage />,
      },
    ],
  },
];

export const getRouter = () => createBrowserRouter(getRoutes());
