import React from 'react';

import { NavItem } from './types';
import { TelephoneIcon, MessageIcon } from '../icons';

export const NAV_ITEMS: Array<NavItem> = [
  {
    name: 'aboutCompany',
    label: 'О компании',
    id: 1,
    children: [
      {
        label: 'Менеджемт',
        href: 'https://rosbankinsurance.ru/about/management/',
      },
      {
        label: 'Социальная ответственность',
        href: 'https://rosbankinsurance.ru/about/responsibility/',
      },
      {
        label: 'Финансовые показатели',
        href: 'https://rosbankinsurance.ru/about/finance/',
      },
      {
        label: 'Раскрытие информации',
        href: 'https://rosbankinsurance.ru/about/info_disclosure/',
      },
      {
        label: 'Контакты',
        href: 'https://rosbankinsurance.ru/contacts/',
      },
    ],
  },
  {
    name: 'programs',
    label: 'Программы страхования',
    id: 2,
    href: 'https://rosbankinsurance.ru/insurance/',
  },
  {
    name: 'contact',
    label: 'Связатся с нами',
    id: 3,
    children: [
      {
        label: 'Создать обращение',
        href: 'https://rosbankinsurance.ru/help/feedback/',
        description: 'по любым вопросам страхования',
        icon: <MessageIcon fill="transparent" width="20px" height="20px" />,
      },
      {
        label: '8 800 700 7 333',
        href: '#',
        description: 'центр поддержки клиентов',
        icon: <TelephoneIcon fill="transparent" width="20px" height="20px" />,
      },
    ],
  },
  {
    name: 'documents',
    label: 'Документы по событию',
    id: 4,
    href: 'https://rosbankinsurance.ru/help/',
  },
];
