import React, { FC, MouseEvent, useState } from 'react';

import { Datepicker, DatepickerProps } from '../../Datepicker';
import { FormControl, type Props as FormControlProps } from '../../FormControl';

export type Props = DatepickerProps & FormControlProps;

export const FormDatepicker: FC<Props> = ({
  label,
  legend,
  isInvalid,
  isDisabled,
  isRequired,
  errorText,
  fieldProps,
  onClickOutside,
  onInputClick,
  maxDate,
  value,
  ...inputProps
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClickOutside = (event: MouseEvent<HTMLDivElement>) => {
    setIsOpen(false);
    onClickOutside?.(event);
  };

  const handleInputClick = () => {
    setIsOpen(true);
    onInputClick?.();
  };

  return (
    <FormControl
      label={label}
      legend={legend}
      isInvalid={isInvalid}
      errorText={errorText}
      isDisabled={isDisabled}
      isRequired={isRequired}
      hasValue={!!value}
      value={value}
      isMenuOpen={isOpen}
    >
      <Datepicker
        value={value}
        hasLabel={Boolean(label)}
        isInvalid={isInvalid}
        fieldProps={fieldProps}
        maxDate={maxDate}
        {...inputProps}
        onClickOutside={handleClickOutside}
        onInputClick={handleInputClick}
      />
    </FormControl>
  );
};
