import React, { FC } from 'react';

import { FormRadioGroup, RadioGroupProps } from '@/shared/ui';

import { useField } from '../lib';
import type { FormControlProps } from './types';

export type Props = Omit<RadioGroupProps, 'value'> & FormControlProps;

export const RadioGroup: FC<Props> = (props) => {
  const { error, invalid, onChange, value, ...field } = useField<Record<string, any>>({
    name: props.name ?? '',
  });

  return (
    <FormRadioGroup
      {...props}
      isInvalid={invalid}
      errorText={error}
      onChange={onChange}
      value={value ?? ''}
      {...field}
    />
  );
};
