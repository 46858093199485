import React from 'react';
import { MenuList, MenuItem, Box, Stack } from '@chakra-ui/react';
import { cx } from '@chakra-ui/shared-utils';

import { Text } from '../../Text';
import { Checkbox } from '../../Checkbox';
import { Option, SuggestProps } from './types';

export const MultipleSuggest: React.FC<SuggestProps> = ({
  onSelect,
  selected,
  isOptionsLoading,
  options,
  onChange,
}) => {
  const handleChange = (selectedOption: Option): void => {
    if (Array.isArray(selected)) {
      const selectedOptionIndex = selected.findIndex((opt) => opt.value === selectedOption.value);
      const isExist = selectedOptionIndex !== -1;

      if (isExist) {
        const updatedValue = [...selected];
        updatedValue.splice(selectedOptionIndex, 1);
        onSelect(updatedValue);
        onChange([...updatedValue]);
      } else {
        onSelect([...selected, selectedOption]);
        onChange([...selected, selectedOption]);
      }
    }
  };

  return (
    <Stack
      sx={{
        div: {
          minWidth: 'auto !important',
        },
      }}
    >
      <MenuList
        minW="200px"
        maxH={{ base: '280px', md: '370px' }}
        rootProps={{
          style: {
            width: '100%',
          },
        }}
        zIndex="121"
        padding="12px 16px"
        boxShadow="0px 6px 24px 0px rgba(0, 0, 0, 0.05)"
        bg="greySun"
        width="inherit"
        border="1px solid"
        borderColor="greySaturn"
        borderRadius="16px"
        overflowY="auto"
        overflowX="hidden"
        sx={{
          '&::-webkit-scrollbar': {
            width: '4px',
            borderRadius: '10px',
            paddingTop: '10px',
          },
          '&::-webkit-scrollbar-track': {
            width: '6px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'greyUranus',
            borderRadius: '10px',
            paddingTop: '10px',
          },
        }}
      >
        {isOptionsLoading ? (
          <Box pl={3}>Загрузка... </Box>
        ) : (
          options.map((option) => (
            <MenuItem
              cursor="pointer"
              key={option.value}
              className={cx('chakra-menu__menuitem')}
              data-focus="true"
              display="flex"
              justifyContent="start"
              alignItems="center"
              flexDirection="row"
              padding="10px 16px"
              bg="greySun"
              _hover={{ cursor: 'pointer' }}
            >
              <Checkbox
                checked={
                  Array.isArray(selected) &&
                  selected.some((opt: Option) => opt.value === option.value)
                }
                onChange={(e) => {
                  e?.stopPropagation();
                  handleChange(option);
                }}
                state="selected"
              />
              <Text
                w="100%"
                ml="12px"
                variant="bodyM"
                color="greyPluton"
                onClick={(e) => {
                  e.stopPropagation();
                  handleChange(option);
                }}
              >
                {option.label}
              </Text>
            </MenuItem>
          ))
        )}
      </MenuList>
    </Stack>
  );
};
