import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';

import { Fonts, theme } from '@/shared/ui';

import { Provider } from './types';

export const withUiKit: Provider = (component) => () =>
  (
    <ChakraProvider theme={theme}>
      <Fonts />
      {component()}
    </ChakraProvider>
  );
