import React, { FC, ReactElement } from 'react';
import { FileUploader as FileInput } from 'react-drag-drop-files';
import { Stack } from '@chakra-ui/react';

import { AttachmentIcon, UploadFileIcon, DeleteIcon, WarningIcon } from '../../icons';
import { Text } from '../../Text';
import { isValidSize, parseFileSize } from '../lib';

const fileTypes = ['PNG', 'JPEG', 'MP4', 'DOC', 'DOCX'];

// copypast from react-drag-drop-files
interface FileInputProps {
  name?: string;
  hoverTitle?: string;
  types?: Array<string>;
  classes?: string;
  children?: ReactElement;
  maxSize?: number;
  minSize?: number;
  fileOrFiles?: Array<File> | File | null;
  disabled?: boolean | false;
  label?: string | undefined;
  multiple?: boolean | false;
  required?: boolean | false;
  onSizeError?: (arg0: string) => void;
  onTypeError?: (arg0: string) => void;
  onDrop?: (arg0: File | Array<File>) => void;
  onSelect?: (arg0: File | Array<File>) => void;
  handleChange?: (arg0: File | Array<File> | File) => void;
  onDraggingStateChange?: (dragging: boolean) => void;
  dropMessageStyle?: React.CSSProperties | undefined;
}

export interface Props extends FileInputProps {
  onChange?: (files?: File[]) => void;
  isInvalid?: boolean;
  value?: File[];
  onAdd?: (file: File) => void;
  onRemove?: (fileName: string) => void;
}

export const FileUploader: FC<Props> = ({
  onChange,
  types = fileTypes,
  isInvalid,
  value = [],
  maxSize = 99999,
  onAdd,
  onRemove,
  disabled,
  ...restProps
}) => {
  const fileTypesToAttach = types
    .map((type: string) => `.${type}`)
    .join(', ')
    .toLowerCase();

  const handleAdd = (file: File) => {
    const files = [...value, file];
    onChange?.(files);
    onAdd?.(file);
  };

  const handleRemove = (fileName: string) => () => {
    if (disabled) {
      return;
    }
    const filteredList = value.filter((file) => file.name !== fileName);
    onChange?.(filteredList);
    onRemove?.(fileName);
  };

  return (
    <Stack gap="16px">
      <FileInput
        types={types}
        handleChange={handleAdd}
        value={value}
        disabled={disabled}
        {...restProps}
      >
        <Stack
          direction="row"
          gap="12px"
          p="16px"
          border="1px dashed"
          borderColor={isInvalid ? 'brandMars' : 'tokensBorder'}
          borderRadius="16px"
          cursor="pointer"
        >
          <UploadFileIcon width="24px" height="24px" />
          <Stack gap="4px">
            <Text variant="body16">Загрузите фото, видео или документы</Text>
            <Text variant="body16" color="textSecondary">
              {fileTypesToAttach}
              {maxSize ? ` до ${maxSize} Мб` : ''}
            </Text>
          </Stack>
        </Stack>
      </FileInput>
      {value.map((file) => (
        <Stack
          key={file.name}
          direction="row"
          p={{ base: '8px 16px', lg: '16px 8px' }}
          justify="space-between"
        >
          <Stack gap="12px" direction="row">
            <AttachmentIcon width="24px" height="24px" />
            <Text variant="body16">{file.name}</Text>
          </Stack>
          <Stack gap="16px" direction="row">
            <Text
              variant="body16"
              color={file.size > maxSize * 1024 * 1024 ? 'brandMars' : 'textSecondary'}
            >
              {parseFileSize(file.size)}
              {isValidSize(file.size, maxSize) ? ` / ${maxSize}` : ''} Mb
            </Text>
            {isValidSize(file.size, maxSize) ? (
              <WarningIcon width="24px" height="24px" />
            ) : (
              <DeleteIcon
                width="24px"
                height="24px"
                cursor="pointer"
                onClick={handleRemove(file.name)}
              />
            )}
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};
