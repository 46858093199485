import React, { FC } from 'react';
import { HStack, useRadioGroup, Show, Hide, Box } from '@chakra-ui/react';
import { UseRadioGroupProps } from '@chakra-ui/radio';

import { RadioTag } from '../../RadioTag';
import { Carousel } from '../../Carousel';

interface Option {
  label: string;
  value: string;
}

export interface Props extends UseRadioGroupProps {
  options: Option[];
}

export const RadioGroup: FC<Props> = ({ options, ...restProps }) => {
  const { getRootProps, getRadioProps } = useRadioGroup(restProps);

  const group = getRootProps();

  return (
    <Box {...group}>
      <Show above="sm">
        <HStack gap="12px" flexWrap="wrap">
          {options.map(({ label, value }) => {
            const radioProps = getRadioProps({ value });
            return <RadioTag tagProps={{ label, size: 'md' }} {...radioProps} />;
          })}
        </HStack>
      </Show>
      <Hide above="md">
        <Box
          sx={{
            '.swiper-wrapper': {
              display: 'flex',
              flexWrap: 'wrap',
              rowGap: '8px',
              w: { base: '400%', lg: 'auto' }, // TODO: разобраться, почему не работает без задания ширины блоку
            },
          }}
        >
          <Carousel<Option>
            slides={options}
            spaceBetween="8px"
            slidesPerView="auto"
            slideToClickedSlide
            getSlideKey={({ value }) => value}
            renderSlide={({ label, value }) => {
              const radioProps = getRadioProps({ value });
              return <RadioTag tagProps={{ label, size: 'md' }} {...radioProps} />;
            }}
          />
        </Box>
      </Hide>
    </Box>
  );
};
