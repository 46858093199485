import React, { FC, RefObject, useMemo, useRef, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { mergeRefs } from '@chakra-ui/react-use-merge-refs';

import { ChevronDownIcon, CloseIcon } from '../../icons';
import { Dropdown, type DropdownProps, type Option } from '../../Dropdown';
import { Input, type InputProps } from '../../Input';

type BaseSelectProps = Omit<
  DropdownProps,
  'children' | 'listRootProps' | 'aboveList' | 'initialFocusRef'
> & {
  onChange: (option: string | number | null | (string | number | null)[]) => void;
} & Pick<InputProps, 'size' | 'variant' | 'name' | 'onFocus' | 'onBlur'>;

export interface Props extends BaseSelectProps {
  value?: Option['value'] | null;
  placeholder?: string;
  isDisabled?: boolean;
  isInvalid?: boolean;
  clearable?: boolean;
  fieldProps?: InputProps;
  onInputChange?: (value: string, inputRef?: RefObject<HTMLInputElement>) => void;
  autocomplete?: 'off' | 'on';
  isMultiple?: boolean;
  isAddress?: boolean;
}

export const Select: FC<Props> = ({
  placeholder,
  isDisabled,
  isInvalid,
  fieldProps,
  variant,
  size,
  clearable = false,
  onInputChange,
  onChange,
  onBlur,
  autocomplete = 'off',
  selected,
  onSelect,
  isMultiple,
  isAddress = false,
  ...props
}) => {
  const selectedItem = useMemo(
    () =>
      props.options.length > 0
        ? props.options.find(({ value }) => value === props.value)
        : { label: props.value, value: props.value },
    [props.options, props.value]
  );
  const [inputValue, setInputValue] = useState(
    (isAddress ? props.value : selectedItem?.label) || ''
  );
  const inputRef = useRef<HTMLInputElement>(null);
  const canType = Boolean(onInputChange);

  const handleSelect = (option: Option | Option[] | null) => {
    if (option) {
      if (!Array.isArray(option)) {
        onChange?.(option.value);
        setInputValue(option?.label ?? '');

        if (option) {
          requestAnimationFrame(() => {
            if (typeof option.label === 'string') {
              inputRef.current?.setSelectionRange(option.label.length, option.label.length);
            }
          });
        }
      } else {
        // TODO: добавить функционал если мултиселект
        // onChange?.([...option.map((item) => item.value)]);
        setInputValue(option?.map((obj) => obj.label).join(', '));
      }
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setInputValue(value);
    onInputChange?.(value, inputRef);
  };

  const handleClear: React.MouseEventHandler<SVGElement> = (event) => {
    event.stopPropagation();

    onChange?.(null);
    setInputValue('');
    onInputChange?.('');
    inputRef.current?.focus();
  };

  return (
    <Dropdown
      {...props}
      selected={selectedItem as Option}
      onSelect={onSelect}
      isMultiple={isMultiple}
      onChange={handleSelect}
    >
      {({ ref: buttonRef, onClick, ...buttonProps }, { isOpen }) => (
        <Input
          {...fieldProps}
          pr="90px"
          key={props.name}
          name={props.name}
          isReadOnly={!canType}
          variant={variant}
          size={size}
          // @ts-ignore
          value={props.value || inputValue || selectedItem?.label}
          placeholder={placeholder}
          textOverflow="ellipsis"
          overflow="hidden"
          isDisabled={isDisabled}
          isInvalid={isInvalid}
          width="100%"
          data-focus-visible={isOpen || undefined}
          autoComplete={autocomplete}
          onBlur={(event) => {
            if (
              !isOpen ||
              (event.relatedTarget &&
                // не самое изящное решение, но нужно для того, что бы не запускать событие блюра при выборе итема
                !event.relatedTarget.className.includes('chakra-menu__menuitem'))
            ) {
              onBlur?.(event);
            }
          }}
          rightIcon={
            <>
              {clearable && inputValue && !isDisabled && (
                <CloseIcon
                  w={2}
                  mr={2}
                  cursor="pointer"
                  onClick={(event) => {
                    handleClear(event);
                    // открываем меню
                    if (!isOpen) {
                      onClick(event);
                    }
                  }}
                />
              )}
              {selected && (
                <Box mt="16px" pr="16px">
                  {Array.isArray(selected) && selected.length > 1 && `(${selected.length})`}
                </Box>
              )}
              <Box cursor="pointer" onClick={onClick}>
                <ChevronDownIcon
                  w="24px"
                  h="24px"
                  transition="all .3s"
                  transform={isOpen ? 'rotate(180deg)' : 'auto'}
                  color="greyNeptune"
                />
              </Box>
            </>
          }
          {...buttonProps}
          ref={mergeRefs(inputRef, buttonRef)}
          onClick={(event) => {
            if (isOpen && canType) return;

            onClick(event);
          }}
          onChange={handleInputChange}
          cursor={canType ? 'inherit' : 'pointer'}
          // хак, что бы у ридонли поля корректно отображались бордеры
          _readOnly={{}}
        />
      )}
    </Dropdown>
  );
};
