import { createContext, useContext } from 'react';

interface PopoverContextProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}

export const PopoverContext = createContext<PopoverContextProps>({
  isOpen: false,
  onOpen: () => {},
  onClose: () => {},
});

export const usePopoverContext = () => useContext(PopoverContext);
