import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const HomeIcon: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 40 40" {...props}>
    <path
      d="M26.3187 5V2.98828H32.152V10.625"
      stroke="#F22557"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.375 37.5H7.79297M35.625 37.5H32.207M7.79297 37.5V20.625M7.79297 37.5H32.207M32.207 37.5V20.625"
      stroke="#343540"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 2.5L2.5 20L5.41667 22.9167L20 8.33334L34.5834 22.9167L37.5 20L20 2.5Z"
      stroke="#343540"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="15.625"
      y="20"
      width="8.75"
      height="7.5"
      rx="1"
      stroke="#F22557"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
