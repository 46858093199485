import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const AttachmentIcon: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <mask id="mask0_7230_17333" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
      <rect width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_7230_17333)">
      <path
        d="M7.5 17.5C5.96667 17.5 4.66667 16.9667 3.6 15.9C2.53333 14.8333 2 13.5333 2 12C2 10.4667 2.53333 9.16667 3.6 8.1C4.66667 7.03333 5.96667 6.5 7.5 6.5H18C19.1 6.5 20.0417 6.89167 20.825 7.675C21.6083 8.45833 22 9.4 22 10.5C22 11.6 21.6083 12.5417 20.825 13.325C20.0417 14.1083 19.1 14.5 18 14.5H8.5C7.8 14.5 7.20833 14.2583 6.725 13.775C6.24167 13.2917 6 12.7 6 12C6 11.3 6.24167 10.7083 6.725 10.225C7.20833 9.74167 7.8 9.5 8.5 9.5H18V11H8.5C8.21667 11 7.97917 11.0958 7.7875 11.2875C7.59583 11.4792 7.5 11.7167 7.5 12C7.5 12.2833 7.59583 12.5208 7.7875 12.7125C7.97917 12.9042 8.21667 13 8.5 13H18C18.7 13 19.2917 12.7583 19.775 12.275C20.2583 11.7917 20.5 11.2 20.5 10.5C20.5 9.8 20.2583 9.20833 19.775 8.725C19.2917 8.24167 18.7 8 18 8H7.5C6.4 8 5.45833 8.39167 4.675 9.175C3.89167 9.95833 3.5 10.9 3.5 12C3.5 13.1 3.89167 14.0417 4.675 14.825C5.45833 15.6083 6.4 16 7.5 16H18V17.5H7.5Z"
        fill="#026B83"
      />
    </g>
  </Icon>
);
