import React, { FC, useMemo } from 'react';
import {
  StackProps,
  Stack,
  Show,
  Hide,
  Link,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react';

import { trackHeaderClick } from '@/shared/lib';

import { Colors } from '../theme';
import { LogoIcon, LogoIconMob } from '../icons';
import { PopoverContext } from './PopoverContext';
import { DesktopNav } from './DesktopNav';
import { MobileNav } from './MobileNav';

interface Props extends StackProps {
  logoColor?: Colors;
  buttonBorder?: string;
  isSticky?: boolean;
  isVisible?: boolean;
  onOpenInsuranceCase: VoidFunction;
}

export const Header: FC<Props> = ({
  isSticky = false,
  isVisible = true,
  bgColor = 'greySun',
  position = 'fixed',
  buttonBorder = '1px solid var(--core-tokens-border, rgba(2, 107, 131, 0.24))',
  boxShadow = '0px 12px 54px 0px rgba(0, 0, 0, 0.05)',
  onOpenInsuranceCase,
  ...restProps
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure({ id: 'headerPopover' });
  const [isMobile] = useMediaQuery('(min-width: 360px) and (max-width: 1279px)');
  const contextValue = useMemo(() => ({ isOpen, onOpen, onClose }), [isOpen, onOpen, onClose]);

  const handleOpenInsuranceCase = () => {
    trackHeaderClick('insuranceApplication');
    onOpenInsuranceCase();
  };

  return (
    <PopoverContext.Provider value={contextValue}>
      <Stack
        px={{ lg: '56px' }}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        position={position}
        top={isSticky && isVisible ? '0' : '-94px'}
        left="0"
        right="0"
        boxShadow={boxShadow}
        bgColor={bgColor}
        zIndex="999"
        transition={isSticky ? 'top .2s ease' : 'none'}
        {...restProps}
      >
        <Link href="https://rosbankinsurance.ru/">
          {isMobile ? (
            <LogoIconMob ml="32px" />
          ) : (
            <LogoIcon
              position="absolute"
              top={{ base: '16px', lg: '32px' }}
              left={{ base: '16px', lg: '56px' }}
              width={{ lg: '220px', xl: '351px' }}
              height={{ lg: '28px', xl: '36px' }}
            />
          )}
        </Link>
        <Show above="lg">
          <DesktopNav
            buttonBorder={buttonBorder}
            isVisible={isVisible}
            onOpenInsuranceCase={handleOpenInsuranceCase}
          />
        </Show>
        <Hide above="lg">
          <MobileNav isSticky={isSticky} onOpenInsuranceCase={handleOpenInsuranceCase} />
        </Hide>
      </Stack>
    </PopoverContext.Provider>
  );
};
