import React from 'react';
import type { MenuProps } from '@chakra-ui/react';
import { Menu, useMenuButton } from '@chakra-ui/react';
import type { ThemingProps } from '@chakra-ui/system';

import { Suggest, SuggestProps } from '../../Suggest';

export type MenuButtonProps = ReturnType<typeof useMenuButton>;
export interface RenderMenuProps {
  isOpen: boolean;
  onClose: VoidFunction;
  forceUpdate?: VoidFunction;
}

type RenderChildren = (
  // Объект с пропсами для элемента, который будет выступать триггером выпадашки. Обязательно нужно передавать
  buttonProps: MenuButtonProps,
  // Объект с пропсами состояния выпадашки
  menuProps: RenderMenuProps
) => React.ReactElement;

type BaseProps = Omit<MenuProps, keyof ThemingProps<'Menu'> | 'children'> & SuggestProps;

export interface Props extends BaseProps {
  children: RenderChildren;
  isOptionsLoading?: boolean;
  isMultiple?: boolean;
}

const MenuButton: React.FC<{
  renderButton: Props['children'];
  menuProps: RenderMenuProps;
}> = ({ renderButton, menuProps }) => {
  const buttonProps = useMenuButton();

  return renderButton(buttonProps, menuProps);
};

export const Dropdown: React.FC<Props> = ({
  children,
  options,
  onChange,
  selected,
  isOptionsLoading,
  onSelect,
  isMultiple = false,
  ...props
}) => (
  <Menu autoSelect={false} {...props} closeOnSelect={!isMultiple}>
    {(menuProps) => (
      <>
        <MenuButton renderButton={children} menuProps={menuProps} />
        {options.length > 0 && (
          <Suggest
            isMultiple={isMultiple}
            options={options}
            selected={selected}
            onSelect={onSelect}
            onChange={onChange}
            isOptionsLoading={isOptionsLoading}
          />
        )}
      </>
    )}
  </Menu>
);
