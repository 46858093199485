import React, { FC } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Button,
  HStack,
  Link,
  VStack,
  Stack,
} from '@chakra-ui/react';

import  {HeaderButtonName, trackHeaderClick } from "@/shared/lib";

import { NAV_ITEMS } from './mock';
import { Text } from '../Text';
import { theme } from '../theme';
import { ChevronUpIcon } from '../icons';

interface Props {
  onOpenInsuranceCase: VoidFunction;
}

export const MobileMenu: FC<Props> = ({ onOpenInsuranceCase }) => {
  const handleButtonClick = (name: HeaderButtonName) => () => {
    trackHeaderClick(name);
  };

  return (
    <>
      <Accordion allowToggle allowMultiple>
        {NAV_ITEMS?.map((item) => (
          <AccordionItem border="0">
            {({ isExpanded }) => (
              <>
                <h3>
                  <AccordionButton
                    px="0"
                    py="12px"
                    _hover={{
                      backgroundColor: 'transparent',
                    }}
                    onClick={handleButtonClick(item.name)}
                  >
                    <HStack justify="space-between" width="100%">
                      {item.children ? (
                        <Text as="span" variant="body16M" color="greyPluton">
                          {item.label}
                        </Text>
                      ) : (
                        <Link
                          href={item.href}
                          color="greyPluton"
                          {...theme.typography.body16}
                          _hover={{
                            textDecoration: 'none',
                          }}
                        >
                          <Text as="span" variant="body16M" color="greyPluton">
                            {item.label}
                          </Text>
                        </Link>
                      )}
                      {item.children && (
                        <ChevronUpIcon
                          transition="all .3s"
                          transform={isExpanded ? 'auto' : 'rotate(180deg)'}
                        />
                      )}
                    </HStack>
                  </AccordionButton>
                </h3>
                <AccordionPanel p="0">
                  <VStack align="left">
                    {item.children?.map((subItem) => (
                      <Link
                        href={subItem.href}
                        px="8px"
                        py="12px"
                        color="greyPluton"
                        textDecoration="none"
                        _hover={{
                          textDecoration: 'none',
                        }}
                        {...theme.typography.body16}
                      >
                        <Stack direction="row" spacing={2}>
                          <Stack justifyContent="center">{subItem?.icon}</Stack>
                          <Stack gap={0}>
                            <Text as="span" variant="body16M" color="greyPluton">
                              {subItem.label}
                            </Text>
                            <Text as="span" variant="body16M" color="greyNeptune">
                              {subItem?.description}
                            </Text>
                          </Stack>
                        </Stack>
                      </Link>
                    ))}
                  </VStack>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        ))}
      </Accordion>

      <VStack gap="16px" py="24px">
        <Button
          onClick={onOpenInsuranceCase}
          width="100%"
          height="52px"
          borderRadius="12px"
          backgroundColor="brandMars"
          _hover={{
            backgroundColor: 'brandMars',
          }}
        >
          <Text as="span" color="greySun" variant="body16M">
            Заявить о страховом событии
          </Text>
        </Button>
      </VStack>
    </>
  );
}
