import React, { FC, ReactElement } from 'react';
import { Box, BoxProps, Stack, Image } from '@chakra-ui/react';

import { Button } from '../Button';
import { TextDescription } from '../TextDescription';

export interface Props extends BoxProps {
  logo?: string;
  title?: string;
  description?: string;
  actionText?: string;
  children?: ReactElement;
  onClick?: () => void;
  sideImage: string;
}

export const NewBanner: FC<Props> = ({
  children,
  title,
  description,
  actionText,
  onClick,
  sideImage,
  ...restProps
}) => (
  <Box
    height={{ base: 'auto', xl: '684px', xxl: '890px' }}
    borderRadius={{ lg: '32px' }}
    display="flex"
    flexDirection={{ base: 'column', lg: 'row' }}
    color="brandSandyYellow"
    py={{ base: '32px', sm: '32px' }}
    pb={{ base: '0', sm: '0' }}
    px={{ base: '16px', sm: '32px', lg: '88px' }}
    bgColor="greySaturn"
    {...restProps}
  >
    {children || (
      <Stack zIndex={3} maxW={{ base: '100%', lg: '50%' }}>
        <Stack
          direction="column"
          justify={{ sm: 'center' }}
          height="100%"
          width={{ base: '100%', lg: '650px', xl: '630px', xxl: '945px' }}
          mt={{ base: '44px', lg: '0' }}
        >
          <Box maxWidth={{ base: '320px', sm: '700px', xl: '636px', xxl: '940px' }}>
            <TextDescription
              size="h1"
              title={title!}
              description={description!}
              titleColor="brandDeepBlue"
              descriptionColor="greyPluton"
            />
            <Button
              width={{ base: '100%', sm: '249px', lg: '327px' }}
              size="md"
              variant="primary"
              text={actionText}
              onClick={onClick}
              mt={{ base: '32px', sm: '48px' }}
              boxShadow="0px 24px 24px 0px #F2285729"
            />
          </Box>
        </Stack>
      </Stack>
    )}
    <Image
      borderBottomRightRadius={{ base: '24px', lg: '24px' }}
      borderBottomLeftRadius={{ base: '24px', lg: '0px' }}
      borderTopRightRadius={{ base: '0px', lg: '24px' }}
      src={sideImage}
      flexGrow={1}
      marginLeft={{ base: 0, lg: '-200px', xxl: '-300px', xxxl: 'unset' }}
      order={1}
      minHeight={{ base: '300px', sm: 'auto' }}
      width="100%"
      marginTop={{ base: 0, sm: '-100px', lg: 0 }}
      objectFit={{ base: 'cover', lg: 'unset' }}
    />
  </Box>
);
