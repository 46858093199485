import React, { FC } from 'react';
import { useTab, forwardRef, Box, TabProps } from '@chakra-ui/react';

import { Tag, TagProps } from '../../Tag';

interface Props extends TagProps, Pick<TabProps, 'key'> {}

export const TabTag: FC<Props> = forwardRef(({ size, label, ...restProps }, ref) => {
  const tabProps = useTab({ ...restProps, ref });
  const isSelected = !!tabProps['aria-selected'];

  return (
    <Box cursor="pointer" {...tabProps}>
      <Tag
        label={label}
        size={size}
        bgColor={isSelected ? 'brandDeepBlue' : 'greySaturn'}
        transition="bgColor .3s"
      />
    </Box>
  );
});
