import React, { FC } from 'react';
import { TextareaProps } from '@chakra-ui/react';

import { Textarea } from '../../Textarea';
import { FormControl, type Props as FormControlProps } from '../../FormControl';

export type Props = TextareaProps &
  Pick<
    FormControlProps,
    'label' | 'isInvalid' | 'isDisabled' | 'isRequired' | 'errorText' | 'hint' | 'hasCounter'
  >;

export const FormTextarea: FC<Props> = ({
  label,
  isInvalid,
  isDisabled,
  isRequired,
  errorText,
  hint,
  maxLength,
  hasCounter,
  ...textareaProps
}) => (
  <FormControl
    label={label}
    isInvalid={isInvalid}
    errorText={errorText}
    isDisabled={isDisabled}
    isRequired={isRequired}
    hasValue={!!textareaProps.value}
    value={textareaProps.value as string}
    isTextarea
    hint={hint}
    counter={maxLength}
    hasCounter={hasCounter}
  >
    <Textarea hasLabel={Boolean(label)} {...textareaProps} />
  </FormControl>
);
