import React from 'react';
import {
  FormHelperText,
  FormErrorMessage,
  FormControl as BaseFormControl,
  FormLabel,
  Text,
  Box,
  type FormControlProps,
  Stack,
} from '@chakra-ui/react';

import { theme } from '../../theme';
import { TitleSecondary } from '../../TitleSecondary';

export interface Props extends Omit<FormControlProps, 'as'> {
  label?: string;
  legend?: string;
  description?: string;
  errorText?: string;
  hasValue?: boolean;
  hasCounter?: boolean;
  hasLeftIcon?: boolean;
  isMenuOpen?: boolean;
  isTextarea?: boolean;
  hint?: string;
  value?: string;
  counter?: number;
}

const textStyles = {
  '& > .chakra-text': {
    top: '12px',
    color: 'greyNeptune',
    ...theme.typography.caption,
    zIndex: 1000,
  },
};

export const FormControl: React.FC<Props> = ({
  children,
  label,
  legend,
  description,
  errorText,
  hasValue,
  isMenuOpen,
  isTextarea,
  hasLeftIcon,
  hint,
  value,
  counter,
  hasCounter,
  ...props
}) => (
  <BaseFormControl
    as="fieldset"
    {...props}
    sx={{
      '& input, textarea': {
        ':focus-visible': {
          borderColor: errorText ? 'statusAndromedae' : '',
        },
      },
    }}
  >
    {legend && (
      <Stack mb={{ base: '16px', lg: '24px' }} gap="8px">
        <TitleSecondary as="legend" color="greyPluton">
          {legend}
        </TitleSecondary>
        {description && <Text variant="bodyS">{description}</Text>}
      </Stack>
    )}
    {label ? (
      <FormLabel
        {...(hasValue ? theme.typography.caption : theme.typography.bodyM)}
        m="0"
        position="relative"
        color="greyNeptune"
        _focus={textStyles}
        sx={isMenuOpen && textStyles}
      >
        {isTextarea && (
          <Box
            position="absolute"
            left="1px"
            right="1px"
            top="1px"
            zIndex="10"
            h="32px"
            pointerEvents="none"
            borderRadius="16px 16px 0 0"
          />
        )}
        <Text
          position="absolute"
          top={{ base: hasValue ? '12px' : '18px', lg: hasValue ? '12px' : '22px' }}
          left={{ base: hasLeftIcon ? '56px' : '16px', lg: hasLeftIcon ? '60px' : '20px' }}
          zIndex="19"
          transition="all .3s .05s"
        >
          {label}
        </Text>
        {children}
      </FormLabel>
    ) : (
      children
    )}
    {hasCounter && !isMenuOpen && (
      <FormHelperText color="greyNeptune" position="relative" left="calc(100% - 7%)">
        {value?.length}/{counter}
      </FormHelperText>
    )}
    {hint && !(errorText && !isMenuOpen) && (
      <FormHelperText color="greyNeptune">{hint}</FormHelperText>
    )}
    {errorText && !isMenuOpen && (
      <FormErrorMessage color="statusAndromedae">{errorText}</FormErrorMessage>
    )}
  </BaseFormControl>
);
